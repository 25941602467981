import React from "react";
import { useNavigate } from "react-router-dom";
import { useMyState } from "../App";

import { ImSpinner9 } from "react-icons/im";
import TabMain from "../components/TabMain";
import isDateToday from "../utils/isDateToday";

function Main() {
  const navigate = useNavigate();
  const { status, firstLoadData } = useMyState();

  return (
    <div className="w-full h-fit ">
      <div className=" mx-8 rounded-xl">
        <div className="w-full text-center pt-6">
          <h1 className="text-xl text-gray-50 font-semibold ">
            ระบบตอกบัตรพนักงานขับรถ
          </h1>
          <h2 className="text-base text-gray-200 font-extralight ">
            กตังค์ ทรานสปอร์ต
          </h2>
        </div>

        <TabMain />

        {firstLoadData?.PunchInData &&
        isDateToday(firstLoadData?.PunchInData?.DriverDatePunchIn) &&
        firstLoadData?.PunchInData?.DriverDatePunchOut ? (
          <div className=" items-center  justify-center text-white mt-20 space-y-2 ">
            <h1 className="text-center ">
              คุณได้ตอกบัตรสำหรับวันนี้ไปแล้วเมื่อ{" "}
              {firstLoadData?.PunchInData?.DriverPunchIn} น.
            </h1>
            <h1 className="text-xs text-center ">
              หากคุณต้องการแก้ไขการตอกบัตรโปรดติดต่อแอดมิน
            </h1>
          </div>
        ) : (
          <div className={`flex items-center p-6 space-x-2 justify-center `}>
            <button
              type="submit"
              className="text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize disabled:bg-gray-500 disabled:hover:bg-gray-500"
              disabled={
                !status ||
                firstLoadData?.User?.LastWork === "PunchIn" ||
                (firstLoadData?.Driver?.LastWork === "PunchIn" &&
                  firstLoadData?.User?.LastWork !== "PunchIn")
              }
              onClick={() => navigate("/punchin")}
            >
              <div className="flex items-center space-x-2">
                <ImSpinner9
                  hidden={status}
                  className="w-4 h-4 text-white animate-spin"
                />
                <span>เข้างาน</span>
              </div>
            </button>

            <button
              type="submit"
              className="text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize disabled:bg-gray-500 disabled:hover:bg-gray-500"
              disabled={
                !status ||
                firstLoadData?.User?.LastWork === "PunchOut" ||
                !firstLoadData?.User?.LastWork
              }
              onClick={() => navigate("/punchout")}
            >
              <div className="flex items-center space-x-2">
                <ImSpinner9
                  hidden={status}
                  className="w-4 h-4 text-white animate-spin"
                />
                <span>ออกงาน</span>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Main;
