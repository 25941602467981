import addComma from "../utils/addComma";

const structure = {
  type: "bubble",
  size: "kilo",
  header: { add: "HEAD" },
  hero: {
    type: "box",
    layout: "vertical",
    contents: [],
    paddingStart: "8px",
    paddingEnd: "8px",
    backgroundColor: "#464F69",
  },
  body: {
    type: "box",
    layout: "vertical",
    contents: [],
    backgroundColor: "#464F69",
    paddingTop: "0px",
    paddingStart: "8px",
    paddingEnd: "8px",
    paddingBottom: "0px",
  },
  footer: {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "button",
        action: {
          type: "uri",
          label: "ตอกบัตรทำงาน",
          uri: "https://liff.line.me/2000769684-l59ZrWX9",
        },
        style: "primary",
        color: "#ffffff1a",
      },
    ],
    backgroundColor: "#464F69",
    paddingAll: "8px",
  },
};

const genHead = (head, date) => {
  return {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "text",
        text: head,
        size: "xl",
        color: "#ffffff",
        align: "center",
      },
      {
        type: "text",
        text: date,
        color: "#ffffffcc",
        size: "sm",
        align: "center",
      },
    ],
    backgroundColor: "#464F69",
    paddingBottom: "8px",
  };
};

const genUser = (head, name, startTime) => {
  const userFlex = {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "text",
        text: head,
        color: "#ffffff",
        size: "sm",
        weight: "bold",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• ชื่อ : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: name,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• เวลา : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: `${startTime} น.`,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
    ],
    margin: "md",
    backgroundColor: "#ffffff1A",
    cornerRadius: "md",
    paddingStart: "13px",
    paddingEnd: "13px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };

  return userFlex;
};

const genTruck = (license, mileageStart) => {
  return {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "text",
        text: "ข้อมูลรถยนต์",
        color: "#ffffff",
        size: "sm",
        weight: "bold",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• ทะเบียน : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: license,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• ไมล์เริ่ม : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: `${addComma(mileageStart)}`,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
    ],
    margin: "md",
    backgroundColor: "#ffffff1A",
    cornerRadius: "md",
    paddingStart: "13px",
    paddingEnd: "13px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };
};

const genImgs = (imgs) => {
  let imgsFlex = {
    type: "box",
    layout: "horizontal",
    contents: [],
    cornerRadius: "md",
  };

  for (const img of imgs) {
    imgsFlex.contents.push({
      type: "image",
      url: img,
      gravity: "center",
      size: "full",
      aspectMode: "cover",
      aspectRatio: "1:1",
      action: {
        type: "uri",
        label: "action",
        uri: img,
      },
    });
  }

  return imgsFlex;
};

const genTransport = (storeNameWithCode, subcontractNameWithCode) => {
  return {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "text",
        text: "ข้อมูลการขนส่ง",
        color: "#ffffff",
        size: "sm",
        weight: "bold",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• สาขา : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: storeNameWithCode,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• สาขา : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: subcontractNameWithCode,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
    ],
    margin: "md",
    backgroundColor: "#ffffff1A",
    cornerRadius: "md",
    paddingStart: "13px",
    paddingEnd: "13px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };
};

export default function genFlexPunchIn({
  img1,
  img2,
  img3,
  img4,
  date,
  license,
  mileageStart,
  driverName,
  driverStartTime,
  hasHelper,
  helperName,
  helperStartTime,
  storeNameWithCode,
  subcontractNameWithCode,
}) {
  const flexHead = genHead("ตอกบัตรเข้างาน", date);
  structure.header = flexHead;

  const flexImg = genImgs(
    hasHelper ? [img1, img2, img3, img4] : [img1, img3, img4]
  );
  structure.hero.contents.push(flexImg);

  const flexDriver = genUser("พนักงานขับรถ", driverName, driverStartTime);

  const flexHelper = genUser("พนักงานขับรถ", helperName, helperStartTime);

  const flexTruck = genTruck(license, mileageStart);

  const flexTrips = genTransport(storeNameWithCode, subcontractNameWithCode);

  structure.body.contents.push(flexDriver);
  if (hasHelper) {
    structure.body.contents.push(flexHelper);
  }
  structure.body.contents.push(flexTruck);
  structure.body.contents.push(flexTrips);
  return structure;
}
