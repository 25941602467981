import cap from "./cap";

export default function makeChoice(listObj, keyLabel, keyValue, keyRecommend) {
  let res = [];

  for (const obj of listObj) {
    res.push({
      label:
        obj[keyLabel] +
        (keyRecommend == null ? "" : ` (${cap(obj[keyRecommend], 13)})`),
      value: obj[keyValue],
      name: obj[keyLabel],
      opt: obj[keyRecommend],
    });
  }

  return res;
}
