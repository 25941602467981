export default Object.freeze({
  Data: {
    User: {
      UserID: "Uec4968235386962e44770b681af153a5",
      Name: "จารวิน พรมสวัสดิ์",
      Nickname: "จา",
      Tel: 987652021,
      Driver: "",
      Position: "Driver",
      LastTruck: "3 ฒฉ 3106 กทม.",
      LastSubcontract: "1",
      LastStore: 17,
      LastLogin: "2023-09-19T14:33:50.695Z",
      LastWork: "PunchIn",
      Wages: "",
      WagesType: "",
      ClockIn: "",
      ClockOut: "",
    },
    Driver: null,
    Helper: {
      UserID: "U05a6a1148e47e094e5ff4469cc0ccb7b",
      Name: "รุ่งนภา เกตุนุกูล",
      Nickname: "ผักบุ้ง",
      Tel: 904765209,
      Driver: "Uec4968235386962e44770b681af153a5",
      Position: "Helper",
      LastTruck: "3 ฒฉ 3106 กทม.",
      LastSubcontract: "1",
      LastStore: 17,
      LastLogin: "2023-09-19T14:33:57.937Z",
      LastWork: "PunchIn",
      Wages: "",
      WagesType: "",
      ClockIn: "",
      ClockOut: "",
    },
    Trucks: [
      {
        TruckLicene: "2 ฒน 2613 กทม.",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3 ฒง 4157 กทม.",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3 ฒฉ 3106 กทม.",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3 ฒท 9097 กทม.",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3ฒช4530",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3ฒฐ 7926",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3ฒฒ3865",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3ฒธ 224 กทม.",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3ฒน 5883",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "3ฒม 162",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "บห 1490",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "ผบ 8316",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "ผพ 540 อุดร",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "ยท 490 เชียงใหม่",
        TruckType: "4W Chilled - Long term",
      },
      {
        TruckLicene: "1ฒห2276",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2 ฒษ 3862",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2 ฒห 1819.",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2ฒข1984",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2ฒฆ153",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2ฒจ8457",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2ฒฐ1252",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2ฒน1680",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2ฒบ6199",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "2ฒร3143",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3 ฒก 8003",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3 ฒค 6485 กทม.",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3 ฒณ4840 กทม",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3 ฒต 8045",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3 ฒย 5157",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒก9633",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒฆ1172",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒฉ6362",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒญ6240",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒต1940",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒถ2617กทม",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒน164",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒบ3833",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒผ8725",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒพ2869",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒว2694",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3ฒศ1348กทม",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ก0704",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บง5011",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บต 6953",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บธ7931",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บน1684",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บม 2335",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บม 9828",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บย9913",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บร2750",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บล 1958 มหาสารคาม",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บล3402",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บล736",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บว3189",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บว7555",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บว9165",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บว9248",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บษ9867",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "บห9493",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผก7828",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผค 6339",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผค6230",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผจ5415",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผฉ8134",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผท9237",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผน 4025 อุบล",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผผ1601",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผพ 5048",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผห 5065",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ผอ8975",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ยข 7861",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ยข667",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ยข984",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ยธ2115",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ยน 3898",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ยบ 3618 นม",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "ยบ2655",
        TruckType: "4W Dry - Long term",
      },
      {
        TruckLicene: "3 ฒญ 9894 กทม.",
        TruckType: "4WJ Dry - Long Term",
      },
      {
        TruckLicene: "3 ฒฐ 1913 กทม.",
        TruckType: "4WJ Dry - Long Term",
      },
    ],
    Stores: [
      {
        StoreCode: 1,
        ST: "ST.01",
        StoreName: "Ladpraw",
        StoreFormat: "CLASSIC A",
        Location: "BKK",
      },
      {
        StoreCode: 2,
        ST: "ST.02",
        StoreName: "Jangwattana",
        StoreFormat: "CLASSIC A",
        Location: "BKK",
      },
      {
        StoreCode: 3,
        ST: "ST.03",
        StoreName: "Srinakarin",
        StoreFormat: "CLASSIC A",
        Location: "BKK",
      },
      {
        StoreCode: 4,
        ST: "ST.04",
        StoreName: "Bangbon",
        StoreFormat: "CLASSIC A",
        Location: "BKK",
      },
      {
        StoreCode: 5,
        ST: "ST.05",
        StoreName: "Chonburi",
        StoreFormat: "CLASSIC A",
        Location: "EAST",
      },
      {
        StoreCode: 6,
        ST: "ST.06",
        StoreName: "Chiangmai",
        StoreFormat: "CLASSIC A",
        Location: "NORTH",
      },
      {
        StoreCode: 7,
        ST: "ST.07",
        StoreName: "Nakhonratchasima",
        StoreFormat: "CLASSIC A",
        Location: "NORTH E.",
      },
      {
        StoreCode: 8,
        ST: "ST.08",
        StoreName: "Rangsit",
        StoreFormat: "CLASSIC A",
        Location: "BKK",
      },
      {
        StoreCode: 9,
        ST: "ST.09",
        StoreName: "Hatyai",
        StoreFormat: "CLASSIC A",
        Location: "SOUTH",
      },
      {
        StoreCode: 10,
        ST: "ST.10",
        StoreName: "Udonthani",
        StoreFormat: "CLASSIC A",
        Location: "NORTH E.",
      },
      {
        StoreCode: 11,
        ST: "ST.11",
        StoreName: "Pisanulok",
        StoreFormat: "CLASSIC A",
        Location: "NORTH",
      },
      {
        StoreCode: 12,
        ST: "ST.12",
        StoreName: "Khonkaen",
        StoreFormat: "CLASSIC A",
        Location: "NORTH E.",
      },
      {
        StoreCode: 13,
        ST: "ST.13",
        StoreName: "Suratthani",
        StoreFormat: "CLASSIC A",
        Location: "SOUTH",
      },
      {
        StoreCode: 14,
        ST: "ST.14",
        StoreName: "Ubonratchathani",
        StoreFormat: "CLASSIC A",
        Location: "NORTH E.",
      },
      {
        StoreCode: 15,
        ST: "ST.15",
        StoreName: "Rayong",
        StoreFormat: "CLASSIC A",
        Location: "EAST",
      },
      {
        StoreCode: 16,
        ST: "ST.16",
        StoreName: "Nakhonsawan",
        StoreFormat: "CLASSIC A",
        Location: "CENTRAL",
      },
      {
        StoreCode: 17,
        ST: "ST.17",
        StoreName: "Charansanitwong",
        StoreFormat: "CLASSIC A",
        Location: "BKK",
      },
      {
        StoreCode: 18,
        ST: "ST.18",
        StoreName: "Sathon",
        StoreFormat: "CLASSIC A",
        Location: "BKK",
      },
      {
        StoreCode: 19,
        ST: "ST.19",
        StoreName: "Nakhonpathom",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 20,
        ST: "ST.20",
        StoreName: "Surin",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 21,
        ST: "ST.21",
        StoreName: "Samsen",
        StoreFormat: "ECO+ B",
        Location: "BKK",
      },
      {
        StoreCode: 22,
        ST: "ST.22",
        StoreName: "Nakhon Si thammarat",
        StoreFormat: "CLASSIC C",
        Location: "SOUTH",
      },
      {
        StoreCode: 23,
        ST: "ST.23",
        StoreName: "Chiangrai",
        StoreFormat: "CLASSIC C",
        Location: "NORTH",
      },
      {
        StoreCode: 24,
        ST: "ST.24",
        StoreName: "Sakhon Nakhon",
        StoreFormat: "CLASSIC C",
        Location: "NORTH E.",
      },
      {
        StoreCode: 25,
        ST: "ST.25",
        StoreName: "Supanburi",
        StoreFormat: "CLASSIC C",
        Location: "CENTRAL",
      },
      {
        StoreCode: 26,
        ST: "ST.26",
        StoreName: "Chantaburi",
        StoreFormat: "CLASSIC C",
        Location: "EAST",
      },
      {
        StoreCode: 27,
        ST: "ST.27",
        StoreName: "Phuket",
        StoreFormat: "ECO+ B",
        Location: "SOUTH",
      },
      {
        StoreCode: 28,
        ST: "ST.28",
        StoreName: "Roi-Et",
        StoreFormat: "CLASSIC C",
        Location: "NORTH E.",
      },
      {
        StoreCode: 29,
        ST: "ST.29",
        StoreName: "Trang",
        StoreFormat: "CLASSIC C",
        Location: "SOUTH",
      },
      {
        StoreCode: 30,
        ST: "ST.30",
        StoreName: "Srakaew",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 31,
        ST: "ST.31",
        StoreName: "Samui",
        StoreFormat: "FOOD SERVICE C",
        Location: "SOUTH",
      },
      {
        StoreCode: 32,
        ST: "ST.32",
        StoreName: "Buriram",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 33,
        ST: "ST.33",
        StoreName: "Srisaket",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 34,
        ST: "ST.34",
        StoreName: "Lumphun",
        StoreFormat: "CLASSIC B",
        Location: "NORTH",
      },
      {
        StoreCode: 35,
        ST: "ST.35",
        StoreName: "Krabi",
        StoreFormat: "ECO+ C",
        Location: "SOUTH",
      },
      {
        StoreCode: 36,
        ST: "ST.36",
        StoreName: "Petchaburi",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 37,
        ST: "ST.37",
        StoreName: "Chaiyaporm",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 38,
        ST: "ST.38",
        StoreName: "Saraburi",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 39,
        ST: "ST.39",
        StoreName: "Pranburi",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 40,
        ST: "ST.40",
        StoreName: "Chachoengsao",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 41,
        ST: "ST.41",
        StoreName: "Chiangmai 2",
        StoreFormat: "ECO+ B",
        Location: "NORTH",
      },
      {
        StoreCode: 42,
        ST: "ST.42",
        StoreName: "Ram-Intra",
        StoreFormat: "CLASSIC B",
        Location: "BKK",
      },
      {
        StoreCode: 43,
        ST: "ST.43",
        StoreName: "Chumporn",
        StoreFormat: "CLASSIC B",
        Location: "SOUTH",
      },
      {
        StoreCode: 44,
        ST: "ST.44",
        StoreName: "Pattaya",
        StoreFormat: "ECO+ B",
        Location: "EAST",
      },
      {
        StoreCode: 45,
        ST: "ST.45",
        StoreName: "Kamphengpetch",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 46,
        ST: "ST.46",
        StoreName: "Kanchanaburi",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 47,
        ST: "ST.47",
        StoreName: "Lopburi",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 48,
        ST: "ST.48",
        StoreName: "Nong Khai",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 49,
        ST: "ST.49",
        StoreName: "Rachaburi",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 50,
        ST: "ST.50",
        StoreName: "Maerim",
        StoreFormat: "CLASSIC B",
        Location: "NORTH",
      },
      {
        StoreCode: 51,
        ST: "ST.51",
        StoreName: "Ayutthaya",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 52,
        ST: "ST.52",
        StoreName: "Lumpang",
        StoreFormat: "CLASSIC B",
        Location: "NORTH",
      },
      {
        StoreCode: 53,
        ST: "ST.53",
        StoreName: "Huahin",
        StoreFormat: "FOOD SERVICE C",
        Location: "CENTRAL",
      },
      {
        StoreCode: 54,
        ST: "ST.54",
        StoreName: "Klongluang",
        StoreFormat: "CLASSIC B",
        Location: "BKK",
      },
      {
        StoreCode: 55,
        ST: "ST.55",
        StoreName: "Loei",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 56,
        ST: "ST.56",
        StoreName: "Phetchabun",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 57,
        ST: "ST.57",
        StoreName: "Bangphli",
        StoreFormat: "CLASSIC B",
        Location: "BKK",
      },
      {
        StoreCode: 58,
        ST: "ST.58",
        StoreName: "Mukdaharn",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 59,
        ST: "ST.59",
        StoreName: "Satun",
        StoreFormat: "CLASSIC B",
        Location: "SOUTH",
      },
      {
        StoreCode: 60,
        ST: "ST.60",
        StoreName: "Trad",
        StoreFormat: "CLASSIC B",
        Location: "EAST",
      },
      {
        StoreCode: 61,
        ST: "ST.61",
        StoreName: "Chumpae",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 62,
        ST: "ST.62",
        StoreName: "Lawai",
        StoreFormat: "FOOD SERVICE E",
        Location: "SOUTH",
      },
      {
        StoreCode: 63,
        ST: "ST.63",
        StoreName: "Nakhon Phanom",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 64,
        ST: "ST.64",
        StoreName: "Pangan",
        StoreFormat: "FOOD SERVICE E",
        Location: "SOUTH",
      },
      {
        StoreCode: 65,
        ST: "ST.65",
        StoreName: "Buengkarn",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 66,
        ST: "ST.66",
        StoreName: "North Pattaya",
        StoreFormat: "FOOD SERVICE D",
        Location: "EAST",
      },
      {
        StoreCode: 67,
        ST: "ST.67",
        StoreName: "Mae Sai",
        StoreFormat: "CLASSIC B",
        Location: "NORTH",
      },
      {
        StoreCode: 68,
        ST: "ST.68",
        StoreName: "Salaya",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 69,
        ST: "ST.69",
        StoreName: "Pattalung",
        StoreFormat: "CLASSIC B",
        Location: "SOUTH",
      },
      {
        StoreCode: 70,
        ST: "ST.70",
        StoreName: "Sukhothai",
        StoreFormat: "CLASSIC C",
        Location: "CENTRAL",
      },
      {
        StoreCode: 71,
        ST: "ST.71",
        StoreName: "Patong",
        StoreFormat: "FOOD SERVICE E",
        Location: "SOUTH",
      },
      {
        StoreCode: 72,
        ST: "ST.72",
        StoreName: "Samutsakorn",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 73,
        ST: "ST.73",
        StoreName: "Mae Sot",
        StoreFormat: "CLASSIC B",
        Location: "NORTH",
      },
      {
        StoreCode: 74,
        ST: "ST.74",
        StoreName: "Pakchong",
        StoreFormat: "ECO+ B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 75,
        ST: "ST.75",
        StoreName: "Ta lang, Phuket",
        StoreFormat: "ECO+ B",
        Location: "SOUTH",
      },
      {
        StoreCode: 76,
        ST: "ST.76",
        StoreName: "Ranong",
        StoreFormat: "CLASSIC B",
        Location: "SOUTH",
      },
      {
        StoreCode: 77,
        ST: "ST.77",
        StoreName: "Lamai",
        StoreFormat: "FOOD SERVICE C",
        Location: "SOUTH",
      },
      {
        StoreCode: 78,
        ST: "ST.78",
        StoreName: "Kalapapuk",
        StoreFormat: "FOOD SERVICE D",
        Location: "BKK",
      },
      {
        StoreCode: 79,
        ST: "ST.79",
        StoreName: "Yasothorn",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 80,
        ST: "ST.80",
        StoreName: "Mahasarakharm",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 81,
        ST: "ST.81",
        StoreName: "Fang",
        StoreFormat: "CLASSIC C",
        Location: "NORTH",
      },
      {
        StoreCode: 82,
        ST: "ST.82",
        StoreName: "Nakorn Nayok",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 83,
        ST: "ST.83",
        StoreName: "Nakhon In",
        StoreFormat: "ECO+ B",
        Location: "BKK",
      },
      {
        StoreCode: 84,
        ST: "ST.84",
        StoreName: "Ban Pae",
        StoreFormat: "FOOD SERVICE D",
        Location: "EAST",
      },
      {
        StoreCode: 85,
        ST: "ST.85",
        StoreName: "Thung Song",
        StoreFormat: "CLASSIC B",
        Location: "SOUTH",
      },
      {
        StoreCode: 86,
        ST: "ST.86",
        StoreName: "Srinakarin 2",
        StoreFormat: "FOOD SERVICE D",
        Location: "BKK",
      },
      {
        StoreCode: 87,
        ST: "ST.87",
        StoreName: "Payaow",
        StoreFormat: "CLASSIC B",
        Location: "NORTH",
      },
      {
        StoreCode: 89,
        ST: "ST.89",
        StoreName: "Pisanulok 2",
        StoreFormat: "ECO+ B",
        Location: "NORTH",
      },
      {
        StoreCode: 91,
        ST: "ST.91",
        StoreName: "Muangmai Market",
        StoreFormat: "FROZEN SHOP",
        Location: "NORTH",
      },
      {
        StoreCode: 92,
        ST: "ST.92",
        StoreName: "Udonthani (Frozen)",
        StoreFormat: "FROZEN SHOP",
        Location: "NORTH E.",
      },
      {
        StoreCode: 93,
        ST: "ST.93",
        StoreName: "Thungsong (Frozen)",
        StoreFormat: "FROZEN SHOP",
        Location: "SOUTH",
      },
      {
        StoreCode: 94,
        ST: "ST.94",
        StoreName: "Ya-Mo (Frozen)",
        StoreFormat: "FROZEN SHOP",
        Location: "NORTH E.",
      },
      {
        StoreCode: 95,
        ST: "ST.95",
        StoreName: "Srirach (Frozen)",
        StoreFormat: "FROZEN SHOP",
        Location: "EAST",
      },
      {
        StoreCode: 96,
        ST: "ST.96",
        StoreName: "Town in Town",
        StoreFormat: "FOOD SHOP",
        Location: "BKK",
      },
      {
        StoreCode: 98,
        ST: "ST.98",
        StoreName: "Nana Charoen Market (Frozen)",
        StoreFormat: "FROZEN SHOP",
        Location: "BKK",
      },
      {
        StoreCode: 100,
        ST: "ST.100",
        StoreName: "Phare",
        StoreFormat: "CLASSIC C",
        Location: "NORTH",
      },
      {
        StoreCode: 101,
        ST: "ST.101",
        StoreName: "Kho Chang",
        StoreFormat: "FOOD SERVICE E",
        Location: "EAST",
      },
      {
        StoreCode: 102,
        ST: "ST.102",
        StoreName: "Nakhonratchasrima 2",
        StoreFormat: "ECO+ B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 103,
        ST: "ST.103",
        StoreName: "Nan",
        StoreFormat: "CLASSIC B",
        Location: "NORTH",
      },
      {
        StoreCode: 104,
        ST: "ST.104",
        StoreName: "Ao Nang",
        StoreFormat: "FOOD SERVICE E",
        Location: "SOUTH",
      },
      {
        StoreCode: 105,
        ST: "ST.105",
        StoreName: "Karon",
        StoreFormat: "FOOD SERVICE E",
        Location: "SOUTH",
      },
      {
        StoreCode: 106,
        ST: "ST.106",
        StoreName: "Warinchamrap",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 107,
        ST: "ST.107",
        StoreName: "Nongbau Lampu",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 108,
        ST: "ST.108",
        StoreName: "Nongchock",
        StoreFormat: "CLASSIC C",
        Location: "BKK",
      },
      {
        StoreCode: 109,
        ST: "ST.109",
        StoreName: "Lamchabang",
        StoreFormat: "FOOD SERVICE C",
        Location: "EAST",
      },
      {
        StoreCode: 110,
        ST: "ST.110",
        StoreName: "Prachuap",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 111,
        ST: "ST.111",
        StoreName: "Phetkasem",
        StoreFormat: "ECO+ B",
        Location: "BKK",
      },
      {
        StoreCode: 112,
        ST: "ST.112",
        StoreName: "Bangpoo",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 113,
        ST: "ST.113",
        StoreName: "Wang Hin",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 114,
        ST: "ST.114",
        StoreName: "Singburi",
        StoreFormat: "CLASSIC B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 115,
        ST: "ST.115",
        StoreName: "Bangbuathong",
        StoreFormat: "ECO+ B",
        Location: "BKK",
      },
      {
        StoreCode: 116,
        ST: "ST.116",
        StoreName: "Kalasin",
        StoreFormat: "CLASSIC B",
        Location: "NORTH E.",
      },
      {
        StoreCode: 117,
        ST: "ST.117",
        StoreName: "Udomsuk",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 118,
        ST: "ST.118",
        StoreName: "Ramkhamhaeng (Sammakorn)",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 119,
        ST: "ST.119",
        StoreName: "Pichit",
        StoreFormat: "CLASSIC C",
        Location: "CENTRAL",
      },
      {
        StoreCode: 120,
        ST: "ST.120",
        StoreName: "Pathumthani",
        StoreFormat: "CLASSIC B",
        Location: "BKK",
      },
      {
        StoreCode: 121,
        ST: "ST.121",
        StoreName: "Bodindecha",
        StoreFormat: "FOOD SERVICE D",
        Location: "BKK",
      },
      {
        StoreCode: 122,
        ST: "ST.122",
        StoreName: "Pracha Uthit",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 123,
        ST: "ST.123",
        StoreName: "Sukhumvit 71",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 124,
        ST: "ST.124",
        StoreName: "Navamin 70",
        StoreFormat: "FOOD SERVICE D",
        Location: "BKK",
      },
      {
        StoreCode: 125,
        ST: "ST.125",
        StoreName: "Cha Am",
        StoreFormat: "FOOD SERVICE D",
        Location: "CENTRAL",
      },
      {
        StoreCode: 126,
        ST: "ST.126",
        StoreName: "On-nuch",
        StoreFormat: "FOOD SERVICE D",
        Location: "BKK",
      },
      {
        StoreCode: 127,
        ST: "ST.127",
        StoreName: "Suksawat",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 128,
        ST: "ST.128",
        StoreName: "Kabinburi",
        StoreFormat: "ECO+ B",
        Location: "CENTRAL",
      },
      {
        StoreCode: 129,
        ST: "ST.129",
        StoreName: "Ladkrabang",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 130,
        ST: "ST.130",
        StoreName: "Nathong Charoen",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 131,
        ST: "ST.131",
        StoreName: "Bo Win",
        StoreFormat: "ECO+ C",
        Location: "EAST",
      },
      {
        StoreCode: 132,
        ST: "ST.132",
        StoreName: "Sattahip",
        StoreFormat: "ECO+ C",
        Location: "EAST",
      },
      {
        StoreCode: 133,
        ST: "ST.133",
        StoreName: "Ram 24",
        StoreFormat: "FOOD SERVICE C",
        Location: "BKK",
      },
      {
        StoreCode: 134,
        ST: "ST.134",
        StoreName: "AmataNakon",
        StoreFormat: "FOOD SERVICE D",
        Location: "EAST",
      },
      {
        StoreCode: 135,
        ST: "ST.135",
        StoreName: "Bangkor-Laem",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 136,
        ST: "ST.136",
        StoreName: "Khonkaen 2",
        StoreFormat: "FOOD SERVICE D",
        Location: "NORTH E.",
      },
      {
        StoreCode: 137,
        ST: "ST.137",
        StoreName: "Wongsakorn 2",
        StoreFormat: "FOOD SERVICE D",
        Location: "BKK",
      },
      {
        StoreCode: 138,
        ST: "ST.138",
        StoreName: "Mengjai",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 139,
        ST: "ST.139",
        StoreName: "Sukhumvit22",
        StoreFormat: "FOOD SERVICE D",
        Location: "BKK",
      },
      {
        StoreCode: 140,
        ST: "ST.140",
        StoreName: "Chan Road",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 141,
        ST: "ST.141",
        StoreName: "Lumlookka",
        StoreFormat: "FOOD SERVICE D",
        Location: "BKK",
      },
      {
        StoreCode: 142,
        ST: "ST.142",
        StoreName: "Phraeksa",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 143,
        ST: "ST.143",
        StoreName: "Pradit manutham",
        StoreFormat: "FOOD SERVICE C",
        Location: "BKK",
      },
      {
        StoreCode: 144,
        ST: "ST.144",
        StoreName: "Rungroj Market",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 145,
        ST: "ST.145",
        StoreName: "Pongphet",
        StoreFormat: "FOOD SERVICE E",
        Location: "BKK",
      },
      {
        StoreCode: 300,
        ST: "ST.300",
        StoreName: "Wongsakorn",
        StoreFormat: "FOOD SHOP",
        Location: "BKK",
      },
      {
        StoreCode: 301,
        ST: "ST.301",
        StoreName: "Rama4",
        StoreFormat: "FOOD SHOP",
        Location: "BKK",
      },
      {
        StoreCode: 302,
        ST: "ST.302",
        StoreName: "Thonglor",
        StoreFormat: "FOOD SHOP",
        Location: "BKK",
      },
      {
        StoreCode: 303,
        ST: "ST.303",
        StoreName: "Sirimankalachan",
        StoreFormat: "FOOD SHOP",
        Location: "NORTH",
      },
      {
        StoreCode: 350,
        ST: "ST.350",
        StoreName: "Ramintra KM.4 (Ease Park)",
        StoreFormat: "Fresh Shop",
        Location: "BKK",
      },
      {
        StoreCode: 351,
        ST: "ST.351",
        StoreName: "Sino Park",
        StoreFormat: "Fresh Shop",
        Location: "BKK",
      },
      {
        StoreCode: 500,
        ST: "ST.500",
        StoreName: "Na kluea",
        StoreFormat: "FROZEN SHOP",
        Location: "EAST",
      },
      {
        StoreCode: 804,
        ST: "ST.804",
        StoreName: "Bangbon (Maknet)",
        StoreFormat: "E-COMMERCE STORE",
        Location: "BKK",
      },
      {
        StoreCode: 808,
        ST: "ST.808",
        StoreName: "Rangsit (Maknet)",
        StoreFormat: "E-COMMERCE STORE",
        Location: "BKK",
      },
    ],
    PunchInData: {
      DriverUserID: "Uec4968235386962e44770b681af153a5",
      DriverName: "จารวิน พรมสวัสดิ์",
      StoreCode: 17,
      StoreName: "Charansanitwong",
      TruckLicene: "3 ฒฉ 3106 กทม.",
      TruckType: "4W Chilled - Long term",
      TruckImage:
        "https://firebasestorage.googleapis.com/v0/b/ktt-timestamp.appspot.com/o/images%2FIMG_3407.jpeg?alt=media&token=00b14a9e-b4ac-472c-a588-ae85eddcc86a",
      SubcontractID: 1,
      SubcontractName: "KTT",
      MileagePunchIn: 4567,
      MileagePunchInImage:
        "https://firebasestorage.googleapis.com/v0/b/ktt-timestamp.appspot.com/o/images%2FIMG_3407.jpeg?alt=media&token=03aebf45-3e7f-4018-ab79-92440ef3665a",
      DriverDatePunchIn: "19/09/2023",
      DriverPunchIn: "1899-12-30T14:38:56.000Z",
      DriverPunchInImage:
        "https://firebasestorage.googleapis.com/v0/b/ktt-timestamp.appspot.com/o/images%2FIMG_3407.jpeg?alt=media&token=9b801152-c2df-4f9a-a093-c1578bf5afd6",
      HelperDatePunchIn: "19/09/2023",
      HelperPunchIn: "1899-12-30T14:38:56.000Z",
      HelperPunchInImage:
        "https://firebasestorage.googleapis.com/v0/b/ktt-timestamp.appspot.com/o/images%2FIMG_3407.jpeg?alt=media&token=394d39f5-e315-4372-97aa-08a982d6beb8",
      MileagePunchOut: "",
      MileagePunchOutImage: "",
      DriverDatePunchOut: "",
      DriverPunchOut: "",
      DriverPunchOutImage: "",
      DriverOT: "",
      HelperOT: "",
      HelperDatePunchOut: "",
      HelperPunchOut: "",
      HelperPunchOutImage: "",
      AmountBill_1: "",
      AmountDrop_1: "",
      Distance_1: "",
      AmountBill_2: "",
      AmountDrop_2: "",
      Distance_2: "",
      AmountBill_3: "",
      AmountDrop_3: "",
      Distance_3: "",
    },
    Subcontracts: [
      {
        SubcontractID: "1",
        SubcontractName: "KTT",
      },
    ],
  },
  Status: 200,
  Message: "OK.",
  Time: "2038 ms",
});
