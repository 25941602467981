/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import { useMyState } from "../App";
import { ImSpinner9 } from "react-icons/im";

// api
import { getSingleDrivers, submitRegister } from "../apis/proxy";

import positionList from "../data/positionList";
import makeChoice from "../utils/makeChoice";
import AlertModal from "../components/AlertModal";
import genRegisFlex from "../flex/genRegisFlex";

function Register() {
  const { status, setStatus, userData, sendFlex, sendText } = useMyState();

  const [isShowModal, setIsShowModal] = useState(false);
  const [singleDriversList, setSingleDriversList] = useState([]);

  const [Position, setPosition] = useState("");
  const [PositionError, setPositionError] = useState(false);

  const [Driver, setDriver] = useState("");
  const [DriverError, setDriverError] = useState(false);

  const [Name, setName] = useState("");
  const [NameError, setNameError] = useState(false);

  const [Nickname, setNickname] = useState("");
  const [NicknameError, setNicknameError] = useState(false);

  const [Tel, setTel] = useState("");
  const [TelError, setTelError] = useState(false);

  const validateForm = () => {
    let isPass = true;

    if (Position == null || Position === "") {
      setPositionError(true);
      isPass = false;
    }

    if (Driver == null || Driver === "") {
      if (Position === "Helper") {
        setDriverError(true);
        isPass = false;
      }
    }

    if (Name == null || Name === "") {
      setNameError(true);
      isPass = false;
    }

    if (Nickname == null || Nickname === "") {
      setNicknameError(true);
      isPass = false;
    }

    if (Tel == null || Tel === "" || Tel.length > 10 || Tel.length < 9) {
      setTelError(true);
      isPass = false;
    } else {
      if (!/^0[0-9]{1,2}[0-9]{3}[0-9]{4}$/.test(Tel)) {
        setTelError(true);
        isPass = false;
      }
    }

    return isPass;
  };

  const handleClickSubmit = async () => {
    const validate = validateForm();
    if (validate) {
      setStatus(false);
      await postSubmitData({
        UserID: userData.UserID,
        Name,
        Nickname,
        Tel,
        Driver,
        Position,
      });
      setStatus(true);
    }
  };

  const postSubmitData = async (data) => {
    try {
      setStatus(false);
      const res = await submitRegister(data);
      setStatus(true);

      if (res.status === 200) {
        if (res.data?.Status === 200) {
          setIsShowModal(true);
          const position =
            Position === "Driver" ? "พนักงานขับรถ" : "ผู้ช่วยพนักงานขับรถ";
          const flex = genRegisFlex({
            picture: userData.Picture,
            name: Name,
            position,
          });

          const res = await sendFlex(flex, "สมัครสำเร็จ");
          if (res === -1) {
            await sendText(`ลงทะเบียนพนักงานสำเร็จ
              ชื่อ : ${Name}
              ตำแหน่ง : ${Position}`);
          }

          setTimeout(async () => {
            setIsShowModal(false);
            window.location.replace("/");
          }, 1500);
        } else {
          alert(res.data?.Message ?? "No response");
        }
      } else {
        alert("network error.");
      }
    } catch (error) {
      alert(error?.response ?? "error");
    }
  };

  const fetchSingleDrivers = async () => {
    try {
      setStatus(false);
      const res = await getSingleDrivers();
      setStatus(true);

      if (res.status === 200) {
        if (res.data?.Status === 200) {
          const choices = makeChoice(
            res.data?.Data?.SingleDrivers,
            "Name",
            "UserID",
            "Nickname"
          );
          setSingleDriversList(choices);
        } else {
          alert(res.data?.Message);
        }
      } else {
        alert("network error.");
      }
    } catch (error) {
      alert(error?.response ?? "error");
    }
  };

  useEffect(() => {
    fetchSingleDrivers();
  }, []);

  useEffect(() => {
    if (Position === "Driver") {
      setDriver("");
    }
  }, [Position]);

  return (
    <div className="w-full h-fit ">
      <AlertModal
        isYesNoModalOpen={[isShowModal, setIsShowModal]}
        message="ลงทะเบียนสำเร็จ"
      />
      <div className=" mx-8 rounded-xl">
        <div className="w-full text-center pt-6 pb-4 ">
          <h1 className="text-xl text-gray-50 font-semibold ">
            ลงทะเบียนพนักงานขับรถ
          </h1>
          <h2 className="text-base text-gray-200 font-extralight ">
            กตังค์ ทรานสปอร์ต
          </h2>
        </div>

        <div className=" mx-5 space-y-3">
          <SelectInput
            label="ตำแหน่งงาน"
            placeholder="โปรดเลือกตำแหน่งงาน"
            list={positionList}
            value={[Position, setPosition]}
            error={[PositionError, setPositionError]}
            required
          />

          <SelectInput
            label="พนักงานขับรถที่ทำงานด้วยกัน"
            placeholder="โปรดเลือกพนักงานขับรถที่ทำงานด้วยกัน"
            value={[Driver, setDriver]}
            error={[DriverError, setDriverError]}
            list={singleDriversList}
            hidden={Position === "Driver" || Position === ""}
            required
          />

          <TextInput
            label="ชื่อ-สกุล"
            type="text"
            placeholder="สมชาย ศักดิ์ศรี"
            value={[Name, setName]}
            error={[NameError, setNameError]}
            required
          />

          <TextInput
            label="ชื่อเล่น"
            type="text"
            placeholder={userData?.DisplayName || "โจ้"}
            value={[Nickname, setNickname]}
            error={[NicknameError, setNicknameError]}
            required
          />

          <TextInput
            label="เบอร์โทร"
            type="tel"
            placeholder="0987654321"
            value={[Tel, setTel]}
            error={[TelError, setTelError]}
            required
          />
        </div>

        <div className="flex items-center p-6 space-x-2 justify-end">
          <button
            type="submit"
            className="text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize disabled:bg-green-500 disabled:hover:bg-green-600"
            onClick={() => handleClickSubmit()}
            disabled={!status}
          >
            <div className="flex items-center space-x-2">
              <ImSpinner9
                hidden={status}
                className="w-4 h-4 text-white animate-spin"
              />
              <span>{status ? "สมัครเข้าใช้งาน" : "กำลังโหลด..."}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Register;
