import addComma from "../utils/addComma";

const structure = {
  type: "bubble",
  size: "kilo",
  header: { add: "HEAD" },
  hero: {
    type: "box",
    layout: "vertical",
    contents: [],
    paddingStart: "8px",
    paddingEnd: "8px",
    backgroundColor: "#464F69",
  },
  body: {
    type: "box",
    layout: "vertical",
    contents: [],
    backgroundColor: "#464F69",
    paddingTop: "0px",
    paddingStart: "8px",
    paddingEnd: "8px",
    paddingBottom: "0px",
  },
  footer: {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "button",
        action: {
          type: "uri",
          label: "ตอกบัตรทำงาน",
          uri: "https://liff.line.me/2000769684-l59ZrWX9",
        },
        style: "primary",
        color: "#ffffff1a",
      },
    ],
    backgroundColor: "#464F69",
    paddingAll: "8px",
  },
};

const genHead = (head, date) => {
  return {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "text",
        text: head,
        size: "xl",
        color: "#ffffff",
        align: "center",
      },
      {
        type: "text",
        text: date,
        color: "#ffffffcc",
        size: "sm",
        align: "center",
      },
    ],
    backgroundColor: "#464F69",
    paddingBottom: "8px",
  };
};

const genUser = (head, name, startTime, endTime, ot) => {
  const userFlex = {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "text",
        text: head,
        color: "#ffffff",
        size: "sm",
        weight: "bold",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• ชื่อ : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: name,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• เวลา : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: `${startTime} ~ ${endTime} `,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
    ],
    margin: "md",
    backgroundColor: "#ffffff1A",
    cornerRadius: "md",
    paddingStart: "13px",
    paddingEnd: "13px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };

  if (ot !== null && ot !== "") {
    userFlex.contents[2].contents.push({
      type: "span",
      text: `(OT ${ot} Hr)`,
      color: "#ffffffde",
      size: "xs",
    });
  }

  return userFlex;
};

const genTruck = (license, mileageStart, mileageEnd) => {
  return {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "text",
        text: "ข้อมูลรถยนต์",
        color: "#ffffff",
        size: "sm",
        weight: "bold",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• ทะเบียน : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: license,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
      {
        type: "text",
        contents: [
          {
            type: "span",
            text: "• ไมล์ : ",
            weight: "bold",
            color: "#ffffffee",
          },
          {
            type: "span",
            text: `${addComma(mileageStart)} ~ ${addComma(mileageEnd)}`,
            color: "#ffffffde",
          },
        ],
        size: "sm",
        offsetStart: "md",
      },
    ],
    margin: "md",
    backgroundColor: "#ffffff1A",
    cornerRadius: "md",
    paddingStart: "13px",
    paddingEnd: "13px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };
};

const genImgs = (imgs) => {
  let imgsFlex = {
    type: "box",
    layout: "horizontal",
    contents: [],
    cornerRadius: "md",
  };

  for (const img of imgs) {
    imgsFlex.contents.push({
      type: "image",
      url: img,
      gravity: "center",
      size: "full",
      aspectMode: "cover",
      aspectRatio: "1:1",
      action: {
        type: "uri",
        label: "action",
        uri: img,
      },
    });
  }

  return imgsFlex;
};

const genTrips = (Trips) => {
  let tripsFlex = {
    type: "box",
    layout: "vertical",
    contents: [
      {
        type: "text",
        text: "ข้อมูลการขนส่ง",
        color: "#ffffff",
        size: "sm",
        weight: "bold",
      },
    ],
    margin: "md",
    backgroundColor: "#ffffff1A",
    cornerRadius: "md",
    paddingStart: "13px",
    paddingEnd: "13px",
    paddingTop: "8px",
    paddingBottom: "8px",
  };

  for (let i = 0; i < Trips.length; i++) {
    tripsFlex.contents.push({
      type: "text",
      contents: [
        {
          type: "span",
          text: `• เที่ยวที่ ${i + 1} : `,
          weight: "bold",
          color: "#ffffffee",
        },
        {
          type: "span",
          text: `จำนวน ${Trips[i].AmountBill} บิล ${Trips[i].AmountDrop} จุด`,
          color: "#ffffffde",
        },
      ],
      size: "sm",
      offsetStart: "md",
    });
    tripsFlex.contents.push({
      type: "text",
      contents: [
        {
          type: "span",
          text: "• ระยะทาง : ",
          weight: "bold",
          color: "#ffffffee",
        },
        {
          type: "span",
          text: `${Trips[i].Distance} ก.ม.`,
          color: "#ffffffde",
        },
      ],
      size: "sm",
      offsetStart: "md",
    });
  }

  return tripsFlex;
};

export default function genFlexPunchOut({
  img1,
  img2,
  img3,
  date,
  license,
  mileageStart,
  mileageEnd,
  driverName,
  driverStartTime,
  driverEndTime,
  driverOT,
  hasHelper,
  helperName,
  helperStartTime,
  helperEndTime,
  helperOT,
  Trips,
}) {
  const flexHead = genHead("ตอกบัตรออกงาน", date);
  structure.header = flexHead;

  const flexImg = genImgs(hasHelper ? [img1, img2, img3] : [img1, img3]);
  structure.hero.contents.push(flexImg);

  const flexDriver = genUser(
    "พนักงานขับรถ",
    driverName,
    driverStartTime,
    driverEndTime,
    driverOT
  );

  const flexHelper = genUser(
    "พนักงานขับรถ",
    helperName,
    helperStartTime,
    helperEndTime,
    helperOT
  );

  const flexTruck = genTruck(license, mileageStart, mileageEnd);

  const flexTrips = genTrips(Trips);

  structure.body.contents.push(flexDriver);
  if (hasHelper) {
    structure.body.contents.push(flexHelper);
  }
  structure.body.contents.push(flexTruck);
  structure.body.contents.push(flexTrips);
  return structure;
}
