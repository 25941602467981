/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMyState } from "../App";

// icons
import { ImSpinner9 } from "react-icons/im";

// api
import { submitPunchOut } from "../apis/proxy";

// components
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import Stepper from "../components/Stepper";
import ImgUploadInput from "../components/ImgUploadInput";
import AlertModal from "../components/AlertModal";
import TripForm from "../components/TripForm";
import UserFormPunchOut from "../components/UserFormPunchOut";

// utils
import makeChoice from "../utils/makeChoice";
import getDateStr from "../utils/dateString";
import getTimeString from "../utils/timeString";
import isValidDate from "../utils/isValidDate";
import isValidDateTime from "../utils/isValidDateTime";

// flex
import genFlexPunchOut from "../flex/genFlexPunchOut";
import addComma from "../utils/addComma";
import sumByKey from "../utils/sumByKey";
import extractPropertyFromArray from "../utils/extractPropertyFromArray";
import isDateToday from "../utils/isDateToday";

function PunchOut() {
  const navigate = useNavigate();
  const tripRef = createRef();

  const [isShowModal, setIsShowModal] = useState(false);
  const [passStep, setPassStep] = useState(1);
  const [curStep, setCurStep] = useState(1);
  const { status, setStatus, firstLoadData, sendFlex, sendMessages, close } =
    useMyState();

  //? ============ STEP1 ============

  const [OT1, setOT1] = useState("");
  const [OT1Error, setOT1Error] = useState(false);

  const [Date1, setDate1] = useState(getDateStr(new Date()));
  const [Date1Error, setDate1Error] = useState(false);

  const [Time1, setTime1] = useState(getTimeString(new Date()));
  const [Time1Error, setTime1Error] = useState(false);

  const ImgRef1 = useRef(null);
  const [SelectImg1, setSelectImg1] = useState(null);
  const [ProgressUpload1, setProgressUpload1] = useState(0);
  const [ImgUrl1, setImgUrl1] = useState("");
  const [ImgUrlError1, setImgUrlError1] = useState(false);

  const [hasHelper, setHasHelper] = useState(false);

  //? ============ STEP2 ============

  const [OT2, setOT2] = useState("");
  const [OT2Error, setOT2Error] = useState(false);

  const [Time2, setTime2] = useState(getTimeString(new Date()));
  const [Time2Error, setTime2Error] = useState(false);

  const ImgRef2 = useRef(null);
  const [ImgUrl2, setImgUrl2] = useState("");
  const [ImgUrlError2, setImgUrlError2] = useState(false);
  const [SelectImg2, setSelectImg2] = useState(null);
  const [ProgressUpload2, setProgressUpload2] = useState(0);

  //? ============ STEP3 ============

  const [TruckLicenseList, setTruckLicenseList] = useState([]);
  const [Mileage, setMileage] = useState("");
  const [MileageError, setMileageError] = useState(false);

  const MileageImageRef = useRef(null);
  const [MileageImage, setMileageImage] = useState("");
  const [MileageImageError, setMileageImageError] = useState(false);
  const [selectImgMileage, setSelectImgMileage] = useState(null);
  const [progressMileageImage, setProgressMileageImage] = useState(0);

  //? ============ STEP4 ============

  const [Trips, setTrips] = useState([]);
  const [TripsError, setTripsError] = useState(false);
  const [DistanceError, setDistanceError] = useState(0);

  const validateStep = (step) => {
    const isNumber = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/;
    let isPass = true;

    //? ============ STEP1 ============
    const lastData = firstLoadData?.PunchInData;
    const punchinDate = lastData?.DriverDatePunchIn;
    const punchinTime = lastData?.DriverPunchIn;

    if (Date1 == null || Date1 === "") {
      setDate1Error(true);
      isPass = false;
    }
    if (!isValidDate(Date1)) {
      setDate1Error(true);
      isPass = false;
    }

    if (!isValidDate(punchinDate, Date1)) {
      setDate1Error(true);
      isPass = false;
    }

    if (Time1 == null || Time1 === "") {
      setTime1Error(true);
      isPass = false;
    }
    if (!isValidDateTime(punchinDate, punchinTime, Date1, Time1)) {
      setTime1Error(true);
      isPass = false;
    }

    if (ImgUrl1 == null || ImgUrl1 === "") {
      setImgUrlError1(true);
      isPass = false;
    }

    if (!isPass) {
      setCurStep(1);
    }
    if (step === 1) {
      return isPass;
    }

    //? ============ STEP2 ============
    if (hasHelper) {
      const punchinTime = firstLoadData?.PunchInData?.HelperPunchIn;

      if (Time2 == null || Time2 === "") {
        setTime2Error(true);
        isPass = false;
      }
      if (!isValidDateTime(punchinDate, punchinTime, Date1, Time2)) {
        setTime2Error(true);
        isPass = false;
      }

      if (ImgUrl2 == null || ImgUrl2 === "") {
        setImgUrlError2(true);
        isPass = false;
      }

      if (!isPass) {
        setCurStep(2);
      }
      if (step === 2) {
        return isPass;
      }
    }

    //? ============ STEP3 ============
    if (!isNumber.test(Mileage)) {
      setMileageError(true);
      isPass = false;
    }
    if (Mileage == null || Mileage === "") {
      setMileageError(true);
      isPass = false;
    }
    if (firstLoadData?.PunchInData?.MileagePunchIn) {
      if (Mileage < firstLoadData?.PunchInData?.MileagePunchIn) {
        setMileageError(true);
        isPass = false;
      }
    }

    if (MileageImage == null || MileageImage === "") {
      setMileageImageError(true);
      isPass = false;
    }

    if (!isPass) {
      setCurStep(3);
    }
    if (step === 3) {
      return isPass;
    }

    //? ============ STEP4 ============
    const totalDistance = sumByKey(Trips, "Distance");

    if (firstLoadData?.PunchInData?.MileagePunchIn) {
      const diffMileage = Mileage - firstLoadData?.PunchInData?.MileagePunchIn;
      if (totalDistance !== diffMileage) {
        setDistanceError(diffMileage - totalDistance);
        isPass = false;
      }
    }

    if (Trips.length === 0) {
      setTripsError(true);
      isPass = false;
    }

    if (!isPass) {
      setCurStep(4);
    }
    if (step === 4) {
      return isPass;
    }
  };

  const setTrucreateTruckLicenseList = () => {
    const choices = makeChoice(
      firstLoadData?.Trucks,
      "TruckLicense",
      "TruckLicense",
      "TruckType"
    );
    setTruckLicenseList(choices);
  };

  const handleClickNext = () => {
    const validate = validateStep(curStep);

    if (validate) {
      if (curStep < 4) {
        const nextStep =
          !hasHelper && curStep === 1 ? curStep + 2 : curStep + 1;
        setCurStep(nextStep);

        if (nextStep > passStep) {
          setPassStep(nextStep);
        }
      } else if (curStep === 4) {
        if (status) {
          const tripConfirm = tripRef.current.handleAddSubmit();
          if (tripConfirm) {
            handleSubmit();
          }
        }
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const DriverName =
        firstLoadData?.User?.Position === "Driver"
          ? firstLoadData?.User?.Name
          : firstLoadData?.Driver?.Name;

      const HelperName =
        firstLoadData?.User?.Position === "Helper"
          ? firstLoadData?.User?.Name
          : firstLoadData?.Helper?.Name;

      const DriverUserID =
        firstLoadData?.User?.Position === "Driver"
          ? firstLoadData?.User?.UserID
          : firstLoadData?.Driver?.UserID;

      const data = {
        DriverUserID,
        DriverName,
        MileagePunchOut: Mileage,
        MileagePunchOutImage: MileageImage,
        DriverDatePunchOut: Date1,
        DriverPunchOut: Time1,
        DriverPunchOutImage: ImgUrl1,
        DriverOT: OT1,
        Trips: Trips,
      };

      if (hasHelper) {
        data.HelperDatePunchOut = Date1;
        data.HelperPunchOut = Time2;
        data.HelperPunchOutImage = ImgUrl2;
        data.HelperOT = OT2;
      }

      setStatus(false);
      const res = await submitPunchOut(data);
      setStatus(true);

      if (res.status === 200) {
        if (res.data?.Status === 200) {
          setIsShowModal(true);

          const lastData = firstLoadData?.PunchInData;
          const TruckLicense = lastData?.TruckLicense ?? "";
          const mileageStart = lastData?.MileagePunchIn ?? "";
          const driverStartTime = lastData?.DriverPunchIn;
          const helperStartTime = lastData?.HelperPunchIn;

          const data = {
            img1: ImgUrl1,
            img2: ImgUrl2,
            img3: MileageImage,
            date: Date1,
            license: TruckLicense,
            mileageStart: mileageStart,
            mileageEnd: Mileage,
            driverName: DriverName,
            driverStartTime: driverStartTime,
            driverEndTime: Time1,
            driverOT: OT1,
            hasHelper: hasHelper,
            helperName: HelperName,
            helperStartTime: helperStartTime,
            helperEndTime: Time2,
            helperOT: OT2,
            Trips: Trips,
          };

          const flex = genFlexPunchOut(data);
          const res = await sendFlex(flex, "ตอกบัตรออกงานสำเร็จ");
          if (res === -1) {
            await sendMessages([
              {
                type: "text",
                text: "ตอกบัตรออกงานสำเร็จ",
              },
            ]);
          }

          setTimeout(async () => {
            setIsShowModal(false);
            close();
          }, 1500);
        } else {
          alert(res.data?.Message);
        }
      } else {
        alert("network error.");
      }
    } catch (error) {
      alert(error?.response ?? "error");
    }
  };

  const handleBack = () => {
    if (curStep > 1) {
      setCurStep(curStep === 3 && !hasHelper ? curStep - 2 : curStep - 1);
    } else if (curStep === 1) {
      if (status) {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (firstLoadData) {
      setTrucreateTruckLicenseList();

      if (isDateToday(firstLoadData?.PunchInData?.DriverDatePunchOut)) {
        window.location.href = "/";
      }

      if (firstLoadData?.PunchInData?.HelperPunchInImage !== "") {
        setHasHelper(true);
      } else {
        setHasHelper(false);
      }
    }
  }, [firstLoadData]);

  return (
    <div className="w-full h-fit ">
      <AlertModal
        isYesNoModalOpen={[isShowModal, setIsShowModal]}
        message="ตอกบัตรออกงานสำเร็จ"
      />

      <div className=" mx-8 rounded-xl">
        <div className="w-full text-center pt-6">
          <h1 className="text-xl text-gray-50 font-semibold ">ตอกบัตรออกงาน</h1>
          <h1 className="text-sm text-gray-200 font-light ">
            {curStep === 1
              ? "ข้อมูลพนักงานขับรถ"
              : curStep === 2
              ? "ข้อมูลผู้ช่วยพนักงานขับรถ"
              : curStep === 3
              ? "ข้อมูลเลขไมล์รถ"
              : `ข้อมูลการส่งของเที่ยวที่ ${Trips.length + 1}`}
          </h1>
        </div>
        <div className="pt-2 pb-5">
          <Stepper curStep={[curStep, setCurStep]} passStep={passStep} />
        </div>

        {curStep === 1 ? (
          <div className=" mx-5 space-y-3">
            <UserFormPunchOut
              PrefixId="driver"
              SuffixId="punchout"
              User={
                firstLoadData?.User?.Position === "Driver"
                  ? firstLoadData?.User
                  : firstLoadData?.Driver
              }
              OT={[OT1, setOT1]}
              OTError={[OT1Error, setOT1Error]}
              DateStr={[Date1, setDate1]}
              DateStrError={[Date1Error, setDate1Error]}
              TimeStr={[Time1, setTime1]}
              TimeStrError={[Time1Error, setTime1Error]}
              ImgRef={ImgRef1}
              SelectImg={[SelectImg1, setSelectImg1]}
              ProgressUpload={[ProgressUpload1, setProgressUpload1]}
              ImgUrl={[ImgUrl1, setImgUrl1]}
              ImgUrlError={[ImgUrlError1, setImgUrlError1]}
              lastDate={firstLoadData?.PunchInData?.DriverDatePunchIn}
              lastTime={firstLoadData?.PunchInData?.DriverPunchIn}
            />
          </div>
        ) : curStep === 2 ? (
          <div className=" mx-5 space-y-3">
            <UserFormPunchOut
              PrefixId="helper"
              SuffixId="punchout"
              User={
                firstLoadData?.User?.Position === "Helper"
                  ? firstLoadData?.User
                  : firstLoadData?.Helper
              }
              disabledDate
              OT={[OT2, setOT2]}
              OTError={[OT2Error, setOT2Error]}
              DateStr={[Date1, setDate1]}
              DateStrError={[Date1Error, setDate1Error]}
              TimeStr={[Time2, setTime2]}
              TimeStrError={[Time2Error, setTime2Error]}
              ImgRef={ImgRef2}
              SelectImg={[SelectImg2, setSelectImg2]}
              ProgressUpload={[ProgressUpload2, setProgressUpload2]}
              ImgUrl={[ImgUrl2, setImgUrl2]}
              ImgUrlError={[ImgUrlError2, setImgUrlError2]}
              lastDate={firstLoadData?.PunchInData?.HelperDatePunchIn}
              lastTime={firstLoadData?.PunchInData?.HelperPunchIn}
            />
          </div>
        ) : curStep === 3 ? (
          <div className=" mx-5 space-y-3">
            <SelectInput
              label="ทะเบียนรถที่ใช้งาน"
              placeholder="โปรดเลือกรถที่งาน"
              value={[firstLoadData?.User?.LastTruck, () => {}]}
              error={[false, () => {}]}
              defaultValue={firstLoadData?.User?.LastTruck}
              list={TruckLicenseList}
              disabled
              required
            />

            <TextInput
              label={
                "เลขไมล์รถ" +
                (firstLoadData?.PunchInData?.MileagePunchIn
                  ? ` (ล่าสุด ${firstLoadData?.PunchInData?.MileagePunchIn})`
                  : "")
              }
              type="text"
              placeholder={
                firstLoadData?.PunchInData?.MileagePunchIn
                  ? firstLoadData?.PunchInData?.MileagePunchIn
                  : 20100
              }
              value={[Mileage, setMileage]}
              error={[MileageError, setMileageError]}
              required
            />

            <ImgUploadInput
              id="mileage-punchout"
              label="รูปเลขไมล์รถ"
              url={[MileageImage, setMileageImage]}
              error={[MileageImageError, setMileageImageError]}
              imgRef={MileageImageRef}
              selected={[selectImgMileage, setSelectImgMileage]}
              progress={[progressMileageImage, setProgressMileageImage]}
              required
            />
          </div>
        ) : (
          <TripForm
            ref={tripRef}
            value={[Trips, setTrips]}
            error={[TripsError, setTripsError]}
            distance={[DistanceError, setDistanceError]}
          />
        )}

        <div className={`flex items-center p-6 space-x-2 justify-between`}>
          <button
            type="submit"
            className="text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize disabled:bg-gray-500 disabled:hover:bg-gray-600"
            onClick={() => handleBack()}
            disabled={!status}
            hidden={curStep < 1}
          >
            <div className="flex items-center space-x-2">
              <span>{"ย้อนกลับ"}</span>
            </div>
          </button>

          <button
            type="submit"
            className="text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize disabled:bg-gray-500 disabled:hover:bg-gray-600"
            onClick={() => handleClickNext()}
            disabled={!status}
          >
            <div className="flex items-center space-x-2">
              <ImSpinner9
                hidden={status}
                className="w-4 h-4 text-white animate-spin"
              />
              <span>{curStep < 4 ? "ถัดไป" : "ส่งข้อมูล"}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PunchOut;
