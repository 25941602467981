import { useState, forwardRef, useImperativeHandle } from "react";
import { useMyState } from "../App";

// icons
import { ImSpinner9 } from "react-icons/im";

// components
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";

// utils
import makeChoiceNum from "../utils/makeChoiceNum";
import TripsTable from "./TripsTable";

const TripForm = forwardRef((props, ref) => {
  const { status, firstLoadData } = useMyState();

  const [Trips, setTrips] = props.value;
  const [isError, setIsError] = props.error;
  const [diffDistanceError, setDiffDistanceError] = props.distance;

  const [AmountBill, setAmountBill] = useState(0);
  const [AmountBillError, setAmountBillError] = useState(false);

  const [AmountDrop, setAmountDrop] = useState(0);
  const [AmountDropError, setAmountDropError] = useState(false);

  const [Distance, setDistance] = useState("");
  const [DistanceError, setDistanceError] = useState(false);

  const validateTrip = () => {
    const isNumber = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/;
    let isPass = true;

    if (AmountBill == null || AmountBill === "" || AmountBill === 0) {
      setAmountBillError(true);
      isPass = false;
    }

    if (AmountDrop == null || AmountDrop === "" || AmountDrop === 0) {
      setAmountDropError(true);
      isPass = false;
    }

    if (Distance == null || Distance === "" || parseInt(Distance) === 0) {
      setDistanceError(true);
      isPass = false;
    }

    if (!isNumber.test(Distance)) {
      setDistanceError(true);
      isPass = false;
    }

    return isPass;
  };

  const handleAddSubmit = () => {
    if (AmountBill !== 0 || AmountDrop !== 0 || Distance !== "") {
      const confirmed = window.confirm(
        `คุณต้องการเพิ่มข้อมูลเที่ยวที่ ${Trips.length + 1} ก่อนหรือไหม?`
      );

      if (confirmed) {
        handleClickAdd();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleClickAdd = () => {
    const validate = validateTrip();
    if (validate) {
      setIsError(false);
      setDiffDistanceError(0);
      setTrips([
        ...Trips,
        {
          AmountBill,
          AmountDrop,
          Distance,
        },
      ]);
      setAmountBill(0);
      setAmountDrop(0);
      setDistance("");
    }
  };

  useImperativeHandle(ref, () => ({
    handleAddSubmit,
  }));

  return (
    <div className=" mx-5 space-y-3">
      <TripsTable Trips={[Trips, setTrips]} />
      <SelectInput
        label="จำนวนบิล"
        placeholder="โปรดเลือกจำนวนบิล"
        value={[AmountBill, setAmountBill]}
        error={[AmountBillError, setAmountBillError]}
        list={makeChoiceNum(20, 1, false, 0)}
        required
      />
      <SelectInput
        label="จำนวนจุดส่งของ"
        placeholder="โปรดเลือกจำนวนจุดส่งของ"
        value={[AmountDrop, setAmountDrop]}
        error={[AmountDropError, setAmountDropError]}
        list={makeChoiceNum(20, 1, false, 0)}
        required
      />
      <TextInput
        label="ระยะทาง (ก.ม.)"
        type="text"
        placeholder={
          firstLoadData?.PunchInData?.Distance_1 !== ""
            ? firstLoadData?.PunchInData?.Distance_1
            : 340
        }
        value={[Distance, setDistance]}
        error={[DistanceError, setDistanceError]}
        required
      />
      <div className="ml-2">
        <span className=" text-xs text-red-500 font-medium ">
          {isError ? `กรุณาเพิ่มข้อมูลการส่งของอย่างน้อย 1 เที่ยว` : ""}
        </span>
      </div>

      <div className="ml-2">
        <span className="text-xs text-red-500 font-medium">
          {diffDistanceError > 0 ? (
            <>
              ระยะทางรวมไม่ถูกต้อง
              <br />
              กรุณาเพิ่มข้อมูลระยะทางให้เท่ากับเลขไมล์ (+{diffDistanceError})
            </>
          ) : diffDistanceError < 0 ? (
            <>
              ระยะทางรวมไม่ถูกต้อง
              <br />
              กรุณาลดข้อมูลระยะทางให้เท่ากับเลขไมล์ ({diffDistanceError})
            </>
          ) : (
            ""
          )}
        </span>
      </div>

      <div className={`flex items-center pt-1 pb-2 justify-end`}>
        <button
          type="submit"
          className="text-gray-600  bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize disabled:bg-gray-500 disabled:hover:bg-gray-600"
          onClick={() => handleClickAdd()}
          disabled={!status}
        >
          <div className="flex items-center space-x-2">
            <ImSpinner9
              hidden={status}
              className="w-4 h-4 text-white animate-spin"
            />
            <span>{`เพิ่มข้อมูลเที่ยวที่ ${Trips.length + 1}`}</span>
          </div>
        </button>
      </div>
    </div>
  );
});

export default TripForm;
