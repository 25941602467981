import React from "react";

// components
import TextInput from "./TextInput";
import TimeInput from "./TimeInput";
import ImgUploadInput from "./ImgUploadInput";
import SelectInput from "./SelectInput";
import DateTextInput from "./DateTextInput";

// utils
import isDateToday from "../utils/isDateToday";
import makeChoiceNum from "../utils/makeChoiceNum";
import getDateStr from "../utils/getDateStr";
import getPastDate from "../utils/getPastDate";
import TimeTextInput from "./TimeTextInput";
import getTimeStr from "../utils/getTimeStr";

function UserFormPunchOut(props) {
  const { User, PrefixId, SuffixId, disabledDate, lastDate, lastTime } = props;

  const [OT, setOT] = props.OT;
  const [OTError, setOTError] = props.OTError;

  const [DateStr, setDateStr] = props.DateStr;
  const [DateStrError, setDateStrError] = props.DateStrError;

  const [TimeStr, setTimeStr] = props.TimeStr;
  const [TimeStrError, setTimeStrError] = props.TimeStrError;

  const ImgRef = props.ImgRef;
  const [SelectImg, setSelectImg] = props.SelectImg;
  const [ProgressUpload, setProgressUpload] = props.ProgressUpload;

  const [ImgUrl, setImgUrl] = props.ImgUrl;
  const [ImgUrlError, setImgUrlError] = props.ImgUrlError;

  return (
    <>
      <TextInput
        label="ชื่อ-สกุล"
        type="text"
        placeholder={User?.Name || ""}
        value={[User?.Name || "", () => {}]}
        error={[false, () => {}]}
        disabled
        required
      />

      <SelectInput
        label="จำนวนโอที (ชั่วโมง)"
        placeholder="กรุณาเลือกจำนวนโอที (หากมี)"
        value={[OT, setOT]}
        error={[OTError, setOTError]}
        list={makeChoiceNum(8, 0.5, false, 0)}
      />

      <DateTextInput
        label={"วันที่ตอกบัตร" + (lastDate ? ` (ล่าสุด ${lastDate})` : "")}
        id={`${PrefixId}-${SuffixId}`}
        min={getDateStr(getPastDate(7, 0, 0))}
        max={getDateStr()}
        value={[DateStr, setDateStr]}
        error={[DateStrError, setDateStrError]}
        disabled={disabledDate}
        required
      />

      <TimeTextInput
        label={"เวลาตอกบัตร" + (lastTime ? ` (ล่าสุด ${lastTime})` : "")}
        max={isDateToday(DateStr) ? getTimeStr() : null}
        value={[TimeStr, setTimeStr]}
        error={[TimeStrError, setTimeStrError]}
        required
      />

      <ImgUploadInput
        id={`${PrefixId}-${SuffixId}`}
        label="รูปตอกบัตรออกงาน"
        imgRef={ImgRef}
        url={[ImgUrl, setImgUrl]}
        error={[ImgUrlError, setImgUrlError]}
        selected={[SelectImg, setSelectImg]}
        progress={[ProgressUpload, setProgressUpload]}
        required
      />
    </>
  );
}

export default UserFormPunchOut;
