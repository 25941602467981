import React from "react";

function TextInput(props) {
  const [value, setValue] = props.value;
  const [isError, setIsError] = props.error;
  const { label, placeholder, type, required, disabled, hidden } = props;

  if (!hidden) {
    return (
      <div className="col-span-6 sm:col-span-3 ">
        <label className="block mb-2 text-sm text-gray-50 capitalize">
          {`${label ?? ""} ${required ? "*" : ""}`}
        </label>

        <input
          type={type}
          className=" bg-gray-50 border border-gray-800 text-gray-800  text-sm rounded-lg  focus:ring-0 block w-full focus:outline-none disabled:text-black p-3 focus:border-gray-800 "
          value={value}
          onChange={(e) => {
            setIsError(false);
            setValue(e.target.value);
          }}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />

        <div className="ml-2">
          <span className=" text-xs text-red-500 font-medium ">
            {isError ? `ข้อมูล${label} ไม่ถูกต้อง!` : ""}
          </span>
        </div>
      </div>
    );
  }
}

export default TextInput;
