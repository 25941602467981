const datetimeStr2Datetime = (dateString, timeString) => {
  const datePattern = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
  const dateMatch = dateString.match(datePattern);

  if (!dateMatch) {
    return false;
  }
  const day = parseInt(dateMatch[1], 10);
  const month = parseInt(dateMatch[2], 10) - 1; // Months are zero-based
  const year = parseInt(dateMatch[3], 10);

  const timePattern = /^(\d{1,2}):(\d{1,2})$/;
  const timeMatch = timeString.match(timePattern);

  const hours = parseInt(timeMatch[1], 10);
  const minutes = parseInt(timeMatch[2], 10);

  return new Date(year, month, day, hours, minutes);
};

export { datetimeStr2Datetime };

export default function isValidDateTime(
  startDateString,
  startTimeString,
  endDateString,
  endTimeString
) {
  const startDate = datetimeStr2Datetime(startDateString, startTimeString);
  if (!startDate) {
    return false;
  }

  let endDate;
  if (endDateString) {
    endDate = datetimeStr2Datetime(endDateString, endTimeString);
    if (!endDate) {
      return false;
    }
  } else {
    endDate = new Date();
  }

  return startDate <= endDate;
}
