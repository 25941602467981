export default function genRegisFlex(props) {
  const { picture, name, position } = props;
  return {
    type: "bubble",
    size: "mega",
    body: {
      type: "box",
      layout: "horizontal",
      contents: [
        {
          type: "box",
          layout: "horizontal",
          contents: [
            {
              type: "box",
              layout: "horizontal",
              contents: [
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "image",
                      url: picture,
                      aspectMode: "cover",
                      size: "full",
                      margin: "none",
                    },
                  ],
                  cornerRadius: "100px",
                  width: "72px",
                  height: "72px",
                  margin: "none",
                  spacing: "none",
                  offsetTop: "5px",
                },
                {
                  type: "box",
                  layout: "vertical",
                  contents: [
                    {
                      type: "box",
                      layout: "vertical",
                      contents: [
                        {
                          type: "text",
                          text: `ชื่อ : ${name}`,
                          color: "#ffffff",
                          size: "md",
                          weight: "bold",
                        },
                        {
                          type: "text",
                          text: `ตำแหน่ง : ${position}`,
                          size: "sm",
                          color: "#ffffff",
                          weight: "regular",
                        },
                      ],
                      spacing: "sm",
                      margin: "md",
                      cornerRadius: "xl",
                      paddingAll: "lg",
                    },
                  ],
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  borderWidth: "light",
                  offsetStart: "-10px",
                },
              ],
              spacing: "lg",
              paddingAll: "sm",
            },
          ],
          spacing: "lg",
          backgroundColor: "#464F69",
          alignItems: "center",
          paddingTop: "20px",
          paddingBottom: "10px",
          paddingStart: "20px",
        },
        {
          type: "box",
          layout: "horizontal",
          contents: [
            {
              type: "text",
              text: "ลงทะเบียนสำเร็จ",
              color: "#FFFFFF",
              size: "xs",
              align: "center",
            },
          ],
          position: "absolute",
          offsetTop: "10px",
          width: "110px",
          height: "25px",
          backgroundColor: "#33CC33",
          cornerRadius: "md",
          paddingAll: "2px",
          paddingEnd: "4px",
          alignItems: "center",
          paddingStart: "4px",
          offsetEnd: "10px",
        },
      ],
      paddingAll: "0px",
    },
  };
}
