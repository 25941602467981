import axios from "axios";

export const api = axios.create({
  baseURL: "https://proxy-yi43lxqvza-uc.a.run.app",
});

export const firstLoad = async (userid) => {
  try {
    return await api.post("/first-load", { UserID: userid });
  } catch (error) {
    return error?.response;
  }
};

export const getSingleDrivers = async () => {
  try {
    return await api.get("/single-drivers");
  } catch (error) {
    return error?.response;
  }
};

const submitRegisterData = {
  UserID: 6789,
  Name: "pakbung naja",
  Nickname: "pb",
  Tel: "0900000000",
  Driver: "234",
  Position: "Helper",
};
export const submitRegister = async (data) => {
  try {
    return await api.post("/submit-register", data);
  } catch (error) {
    return error?.response;
  }
};

const submitPunchInData = {
  UserID: 1231,
  Name: "ongarch",
  StoreCode: 5,
  StoreName: "Chonburi",
  TruckLicense: "3ฒช4530",
  TruckType: "4W Chilled - Long term",
  TruckImage:
    "https://drive.google.com/file/d/17TsFcNjBsXnA_WnQ08gE5VNpl5yXY0Bd/view?usp=drivesdk",
  SubcontractID: 1,
  SubcontractName: "KTT",
  MileagePunchIn: 45673,
  MileagePunchInImage:
    "https://drive.google.com/file/d/17TsFcNjBsXnA_WnQ08gE5VNpl5yXY0Bd/view?usp=drivesdk",
  DriverDatePunchIn: "9/14/2023",
  DriverPunchIn: "2:58:07 PM",
  DriverPunchInImage:
    "https://drive.google.com/file/d/17TsFcNjBsXnA_WnQ08gE5VNpl5yXY0Bd/view?usp=drivesdk",
  HelperDatePunchIn: "9/14/2023",
  HelperPunchIn: "2:58:53 PM",
  HelperPunchInImage:
    "https://drive.google.com/file/d/17TsFcNjBsXnA_WnQ08gE5VNpl5yXY0Bd/view?usp=drivesdk",
};
export const submitPunchIn = async (data) => {
  try {
    return await api.post("/submit-punchin", data);
  } catch (error) {
    return error?.response;
  }
};

const submitPunchOutData = {
  UserID: 1231,
  Name: "ongarch",
  MileagePunchOut: 43565,
  MileagePunchOutImage:
    "https://drive.google.com/file/d/17TsFcNjBsXnA_WnQ08gE5VNpl5yXY0Bd/view?usp=drivesdk",
  DriverDatePunchOut: "9/14/2023",
  DriverPunchOut: "2:58:07 PM",
  DriverPunchOutImage:
    "https://drive.google.com/file/d/17TsFcNjBsXnA_WnQ08gE5VNpl5yXY0Bd/view?usp=drivesdk",
  HelperDatePunchOut: "9/14/2023",
  HelperPunchOut: "2:58:07 PM",
  HelperPunchOutImage:
    "https://drive.google.com/file/d/17TsFcNjBsXnA_WnQ08gE5VNpl5yXY0Bd/view?usp=drivesdk",
  DriverOT: 1.5,
  HelperOT: 0,
  Trips: [
    {
      AmountBill: 5,
      AmountDrop: 3,
      Distance: 200,
    },
    {
      AmountBill: 3,
      AmountDrop: 2,
      Distance: 155,
    },
  ],
};
export const submitPunchOut = async (data) => {
  try {
    return await api.post("/submit-punchout", data);
  } catch (error) {
    return error?.response;
  }
};

export const uploadImage = async (image, userid, type) => {
  const params = new URLSearchParams({
    userid,
    type,
  });

  try {
    return await api.post(`/upload-image?${params.toString()}`, image, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return error.response;
  }
};
