/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMyState } from "../App";

// icons
import { ImSpinner9 } from "react-icons/im";

// api
import { submitPunchIn } from "../apis/proxy";

// components
import TextInput from "../components/TextInput";
import SelectInput from "../components/SelectInput";
import Stepper from "../components/Stepper";
import CheckBoxInput from "../components/CheckBoxInput";
import ImgUploadInput from "../components/ImgUploadInput";
import AlertModal from "../components/AlertModal";
import UserFormPunchIn from "../components/UserFormPunchIn";

// utils
import makeChoice from "../utils/makeChoice";
import getDateStr from "../utils/dateString";
import getTimeString from "../utils/timeString";
import isValidDate from "../utils/isValidDate";
import getListObjByKey from "../utils/getListObjByKey";
import isValidDateTime from "../utils/isValidDateTime";

// flex
import genFlexPunchIn from "../flex/genFlexPunchIn";
import isDateToday from "../utils/isDateToday";

function PunchIn() {
  const navigate = useNavigate();

  const [isShowModal, setIsShowModal] = useState(false);
  const [passStep, setPassStep] = useState(1);
  const [curStep, setCurStep] = useState(1);
  const { status, setStatus, firstLoadData, sendFlex, sendMessages, close } =
    useMyState();

  //? ============ STEP1 ============

  const [Date1, setDate1] = useState(getDateStr(new Date()));
  const [Date1Error, setDate1Error] = useState(false);

  const [Time1, setTime1] = useState(getTimeString(new Date()));
  const [Time1Error, setTime1Error] = useState(false);

  const ImgRef1 = useRef(null);
  const [ImgUrl1, setImgUrl1] = useState("");
  const [ImgUrlError1, setImgUrlError1] = useState(false);
  const [SelectImg1, setSelectImg1] = useState(null);
  const [ProgressUpload1, setProgressUpload1] = useState(0);

  const [hasHelper, setHasHelper] = useState(false);

  //? ============ STEP2 ============

  const [Time2, setTime2] = useState(getTimeString(new Date()));
  const [Time2Error, setTime2Error] = useState(false);

  const ImgRef2 = useRef(null);
  const [ImgUrl2, setImgUrl2] = useState("");
  const [ImgUrlError2, setImgUrlError2] = useState(false);
  const [SelectImg2, setSelectImg2] = useState(null);
  const [ProgressUpload2, setProgressUpload2] = useState(0);

  //? ============ STEP3 ============

  const [TruckLicense, setTruckLicense] = useState("");
  const [TruckLicenseList, setTruckLicenseList] = useState([]);
  const [TruckLicenseError, setTruckLicenseError] = useState("");

  const TruckImageRef = useRef(null);
  const [TruckImage, setTruckImage] = useState("");
  const [TruckImageError, setTruckImageError] = useState(false);
  const [selectImgTruck, setSelectImgTruck] = useState(null);
  const [progressTruckImage, setProgressTruckImage] = useState(0);

  const [Mileage, setMileage] = useState("");
  const [MileageError, setMileageError] = useState(false);

  const MileageImageRef = useRef(null);
  const [MileageImage, setMileageImage] = useState("");
  const [MileageImageError, setMileageImageError] = useState(false);
  const [selectImgMileage, setSelectImgMileage] = useState(null);
  const [progressMileageImage, setProgressMileageImage] = useState(0);

  //? ============ STEP4 ============

  const [Subcontract, setSubcontract] = useState("");
  const [SubcontractList, setSubcontractList] = useState([]);
  const [SubcontractError, setSubcontractError] = useState("");

  const [Store, setStore] = useState("");
  const [StoreList, setStoreList] = useState([]);
  const [StoreError, setStoreError] = useState("");

  const validateStep = (step) => {
    let isPass = true;

    //? ============ STEP1 ============
    if (Date1 == null || Date1 === "") {
      setDate1Error(true);
      isPass = false;
    }
    if (!isValidDate(Date1)) {
      setDate1Error(true);
      isPass = false;
    }

    if (Time1 == null || Time1 === "") {
      setTime1Error(true);
      isPass = false;
    }
    if (!isValidDateTime(Date1, Time1)) {
      setTime1Error(true);
      isPass = false;
    }

    if (ImgUrl1 == null || ImgUrl1 === "") {
      setImgUrlError1(true);
      isPass = false;
    }

    if (!isPass) {
      setCurStep(1);
    }
    if (step === 1) {
      return isPass;
    }

    //? ============ STEP2 ============
    if (hasHelper) {
      if (Time2 == null || Time2 === "") {
        setTime2Error(true);
        isPass = false;
      }
      if (!isValidDateTime(Date1, Time2)) {
        setTime2Error(true);
        isPass = false;
      }

      if (ImgUrl2 == null || ImgUrl2 === "") {
        setImgUrlError2(true);
        isPass = false;
      }

      if (!isPass) {
        setCurStep(2);
      }
      if (step === 2) {
        return isPass;
      }
    }

    //? ============ STEP3 ============
    if (TruckLicense == null || TruckLicense === "") {
      setTruckLicenseError(true);
      isPass = false;
    }

    if (TruckImage == null || TruckImage === "") {
      setTruckImageError(true);
      isPass = false;
    }

    const isNumber = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/;
    if (!isNumber.test(Mileage)) {
      setMileageError(true);
      isPass = false;
    }
    if (Mileage == null || Mileage === "") {
      setMileageError(true);
      isPass = false;
    }
    if (firstLoadData?.PunchInData?.MileagePunchOut) {
      if (Mileage < firstLoadData?.PunchInData?.MileagePunchOut) {
        setMileageError(true);
        isPass = false;
      }
    }

    if (MileageImage == null || MileageImage === "") {
      setMileageImageError(true);
      isPass = false;
    }

    if (!isPass) {
      setCurStep(3);
    }
    if (step === 3) {
      return isPass;
    }

    //? ============ STEP4 ============
    if (Subcontract == null || Subcontract === "") {
      setSubcontractError(true);
      isPass = false;
    }

    if (Store == null || Store === "") {
      setStoreError(true);
      isPass = false;
    }

    if (!isPass) {
      setCurStep(4);
    }
    if (step === 4) {
      return isPass;
    }
  };

  const createTruckLicenseList = () => {
    const choices = makeChoice(
      firstLoadData?.Trucks,
      "TruckLicense",
      "TruckLicense",
      "TruckType"
    );
    setTruckLicenseList(choices);
  };

  const createSubcontractList = () => {
    const choices = makeChoice(
      firstLoadData?.Subcontracts,
      "SubcontractName",
      "SubcontractID",
      "SubcontractID"
    );
    setSubcontractList(choices);
  };

  const createStoreList = () => {
    const choices = makeChoice(
      firstLoadData?.Stores,
      "StoreName",
      "StoreCode",
      "StoreCode"
    );
    setStoreList(choices);
  };

  const handleClickNext = () => {
    const validate = validateStep(curStep);

    if (validate) {
      if (curStep < 4) {
        const nextStep =
          !hasHelper && curStep === 1 ? curStep + 2 : curStep + 1;
        setCurStep(nextStep);

        if (nextStep > passStep) {
          setPassStep(nextStep);
        }
      } else if (curStep === 4) {
        if (status) {
          handleSubmit();
        }
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const SubcontractName = getListObjByKey(
        SubcontractList,
        Subcontract,
        "value",
        "name"
      );
      const TruckType = getListObjByKey(
        TruckLicenseList,
        TruckLicense,
        "value",
        "opt"
      );

      const StoreName = getListObjByKey(StoreList, Store, "value", "name");

      const DriverName =
        firstLoadData?.User?.Position === "Driver"
          ? firstLoadData?.User?.Name
          : firstLoadData?.Driver?.Name;

      const HelperName =
        firstLoadData?.User?.Position === "Helper"
          ? firstLoadData?.User?.Name
          : firstLoadData?.Helper?.Name;

      const DriverUserID =
        firstLoadData?.User?.Position === "Driver"
          ? firstLoadData?.User?.UserID
          : firstLoadData?.Driver?.UserID;

      const data = {
        DriverUserID,
        DriverName,
        StoreCode: Store,
        StoreName,
        TruckLicense: TruckLicense,
        TruckType,
        TruckImage: TruckImage,
        SubcontractID: Subcontract,
        SubcontractName,
        MileagePunchIn: Mileage,
        MileagePunchInImage: MileageImage,
        DriverDatePunchIn: Date1,
        DriverPunchIn: Time1,
        DriverPunchInImage: ImgUrl1,
      };

      if (hasHelper) {
        data.HelperDatePunchIn = Date1;
        data.HelperPunchIn = Time2;
        data.HelperPunchInImage = ImgUrl2;
      }

      console.log(data);

      setStatus(false);
      const res = await submitPunchIn(data);
      setStatus(true);

      if (res.status === 200) {
        if (res.data?.Status === 200) {
          setIsShowModal(true);

          const data = {
            img1: ImgUrl1,
            img2: ImgUrl2,
            img3: TruckImage,
            img4: MileageImage,
            date: Date1,
            license: TruckLicense,
            mileageStart: Mileage,
            driverName: DriverName,
            driverStartTime: Time1,
            hasHelper: hasHelper,
            helperName: HelperName,
            helperStartTime: Time2,
            storeNameWithCode: `${StoreName} (${Store})`,
            subcontractNameWithCode: `${SubcontractName} (${Subcontract})`,
          };

          const flex = genFlexPunchIn(data);
          const res = await sendFlex(flex, "ตอกบัตรเข้างานสำเร็จ");
          if (res === -1) {
            await sendMessages([
              {
                type: "text",
                text: `ตอกบัตรเข้างานสำเร็จ (ไม่สามารถส่งข้อมูลได้)`,
              },
            ]);
          }

          setTimeout(async () => {
            setIsShowModal(false);
            close();
          }, 1500);
        } else {
          alert(res.data?.Message);
        }
      } else {
        alert("network error.");
      }
    } catch (error) {
      alert(error?.response ?? "error");
    }
  };

  const handleBack = () => {
    if (curStep > 1) {
      setCurStep(curStep === 3 && !hasHelper ? curStep - 2 : curStep - 1);
    } else if (curStep === 1) {
      if (status) {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (firstLoadData) {
      createTruckLicenseList();
      createSubcontractList();
      createStoreList();

      if (isDateToday(firstLoadData?.PunchInData?.DriverDatePunchIn)) {
        window.location.href = "/";
      }

      if (firstLoadData?.User?.Position === "Helper") {
        setHasHelper(true);
      }
    }
  }, [firstLoadData]);

  return (
    <div className="w-full h-fit ">
      <AlertModal
        isYesNoModalOpen={[isShowModal, setIsShowModal]}
        message="ตอกบัตรเข้างานสำเร็จ"
      />

      <div className=" mx-8 rounded-xl">
        <div className="w-full text-center pt-6">
          <h1 className="text-xl text-gray-50 font-semibold ">
            ตอกบัตรเข้างาน
          </h1>
          <h1 className="text-sm text-gray-200 font-light ">
            {curStep === 1
              ? "ข้อมูลพนักงานขับรถ"
              : curStep === 2
              ? "ข้อมูลผู้ช่วยพนักงานขับรถ"
              : curStep === 3
              ? "ข้อมูลรถส่งของ"
              : "ข้อมูลร้านค้าและผู้ว่าจ้าง"}
          </h1>
        </div>
        <div className="pt-2 pb-5">
          <Stepper curStep={[curStep, setCurStep]} passStep={passStep} />
        </div>

        {curStep === 1 ? (
          <div className=" mx-5 space-y-3">
            <UserFormPunchIn
              PrefixId="driver"
              SuffixId="punchin"
              User={
                firstLoadData?.User?.Position === "Driver"
                  ? firstLoadData?.User
                  : firstLoadData?.Driver
              }
              DateStr={[Date1, setDate1]}
              DateStrError={[Date1Error, setDate1Error]}
              TimeStr={[Time1, setTime1]}
              TimeStrError={[Time1Error, setTime1Error]}
              ImgRef={ImgRef1}
              SelectImg={[SelectImg1, setSelectImg1]}
              ProgressUpload={[ProgressUpload1, setProgressUpload1]}
              ImgUrl={[ImgUrl1, setImgUrl1]}
              ImgUrlError={[ImgUrlError1, setImgUrlError1]}
            />

            {!(
              firstLoadData?.User?.Position === "Driver" &&
              !firstLoadData?.Helper
            ) && (
              <CheckBoxInput
                id="has-helper-punchin"
                label="มีผู้ช่วยทำงานด้วยหรือไม่"
                value={[hasHelper, setHasHelper]}
                required
              />
            )}
          </div>
        ) : curStep === 2 ? (
          <div className=" mx-5 space-y-3">
            <UserFormPunchIn
              PrefixId="helper"
              SuffixId="punchin"
              User={
                firstLoadData?.User?.Position === "Helper"
                  ? firstLoadData?.User
                  : firstLoadData?.Helper
              }
              disabledDate
              DateStr={[Date1, setDate1]}
              DateStrError={[Date1Error, setDate1Error]}
              TimeStr={[Time2, setTime2]}
              TimeStrError={[Time2Error, setTime2Error]}
              ImgRef={ImgRef2}
              SelectImg={[SelectImg2, setSelectImg2]}
              ProgressUpload={[ProgressUpload2, setProgressUpload2]}
              ImgUrl={[ImgUrl2, setImgUrl2]}
              ImgUrlError={[ImgUrlError2, setImgUrlError2]}
            />
          </div>
        ) : curStep === 3 ? (
          <div className=" mx-5 space-y-3">
            <SelectInput
              label="ทะเบียนรถที่ใช้งาน"
              placeholder="โปรดเลือกรถที่งาน"
              value={[TruckLicense, setTruckLicense]}
              error={[TruckLicenseError, setTruckLicenseError]}
              defaultValue={firstLoadData?.User?.LastTruck}
              list={TruckLicenseList}
              hidden={!TruckLicenseList.length === 0}
              required
            />

            <ImgUploadInput
              id="truck-punchin"
              label="รูปรถที่ใช้งาน"
              url={[TruckImage, setTruckImage]}
              error={[TruckImageError, setTruckImageError]}
              imgRef={TruckImageRef}
              selected={[selectImgTruck, setSelectImgTruck]}
              progress={[progressTruckImage, setProgressTruckImage]}
              required
            />

            <TextInput
              label={
                "เลขไมล์รถ" +
                (firstLoadData?.PunchInData?.MileagePunchOut
                  ? ` (ล่าสุด ${firstLoadData?.PunchInData?.MileagePunchOut})`
                  : "")
              }
              type="text"
              placeholder={firstLoadData?.PunchInData?.MileagePunchOut || 20300}
              value={[Mileage, setMileage]}
              error={[MileageError, setMileageError]}
              required
            />

            <ImgUploadInput
              id="mileage-punchin"
              label="รูปเลขไมล์รถ"
              url={[MileageImage, setMileageImage]}
              error={[MileageImageError, setMileageImageError]}
              imgRef={MileageImageRef}
              selected={[selectImgMileage, setSelectImgMileage]}
              progress={[progressMileageImage, setProgressMileageImage]}
              required
            />
          </div>
        ) : (
          <div className=" mx-5 space-y-3">
            <SelectInput
              label="สาขา"
              placeholder="โปรดเลือกสาขา"
              value={[Store, setStore]}
              error={[StoreError, setStoreError]}
              defaultValue={firstLoadData?.User?.LastStore}
              list={StoreList}
              hidden={!StoreList.length === 0}
              required
            />

            <SelectInput
              label="ผู้ว่าจ้าง"
              placeholder="โปรดเลือกผู้ว่าจ้าง"
              value={[Subcontract, setSubcontract]}
              error={[SubcontractError, setSubcontractError]}
              defaultValue={firstLoadData?.User?.LastSubcontract}
              list={SubcontractList}
              hidden={!SubcontractList.length === 0}
              required
            />
          </div>
        )}

        <div className={`flex items-center p-6 space-x-2 justify-between`}>
          <button
            type="submit"
            className="text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize disabled:bg-gray-500 disabled:hover:bg-gray-600"
            onClick={() => handleBack()}
            disabled={!status}
            hidden={curStep < 1}
          >
            <div className="flex items-center space-x-2">
              <span>{"ย้อนกลับ"}</span>
            </div>
          </button>

          <button
            type="submit"
            className="text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize disabled:bg-gray-500 disabled:hover:bg-gray-600"
            onClick={() => handleClickNext()}
            disabled={!status}
          >
            <div className="flex items-center space-x-2">
              <ImSpinner9
                hidden={status}
                className="w-4 h-4 text-white animate-spin"
              />
              <span>{curStep < 4 ? "ถัดไป" : "ส่งข้อมูล"}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PunchIn;
