export default function getRelativeTime(lastActive) {
  const currentDate = new Date();
  const lastActiveDate = new Date(lastActive);

  const timeDifferenceInSeconds = Math.floor(
    (currentDate - lastActiveDate) / 1000
  );

  if (timeDifferenceInSeconds < 60) {
    return "เร็วๆนี้";
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutes} นาทีที่แล้ว`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hours} ชั่วโมงที่แล้ว`;
  } else if (timeDifferenceInSeconds < 604800) {
    const days = Math.floor(timeDifferenceInSeconds / 86400);
    return `${days} วันที่แล้ว`;
  } else if (timeDifferenceInSeconds < 2419200) {
    const weeks = Math.floor(timeDifferenceInSeconds / 604800);
    return `${weeks} สัปดาห์ที่แล้ว`;
  } else if (timeDifferenceInSeconds < 29030400) {
    const months = Math.floor(timeDifferenceInSeconds / 2419200);
    return `${months} เดือนที่แล้ว`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / 29030400);
    return `${years} ปีที่แล้ว`;
  }
}
