/* eslint-disable no-use-before-define */
"use client";
import { useEffect, useState } from "react";
import { BsFillCalendarEventFill } from "react-icons/bs";
import getDateStr from "../utils/getDateStr";

const DateTextInput = (props) => {
  const [, setValue] = props.value;
  const [isError, setIsError] = props.error;
  const { label, required, disabled, id, max, min } = props;

  const formatYYYYMMDDToDate = (yyyy_mm_dd) => {
    const parts = yyyy_mm_dd.split("-");
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      return `${day}/${month}/${year}`;
    } else {
      return "";
    }
  };

  const [date, setDate] = useState(getDateStr());
  useEffect(() => {
    setValue(formatYYYYMMDDToDate(date));
  }, [date, setValue]);

  return (
    <div className="col-span-6 sm:col-span-3 relative">
      <label className="block mb-2 text-sm text-gray-50 capitalize">
        {`${label ?? ""} ${required ? "*" : ""}`}
      </label>

      <div className="relative rounded-lg">
        <div
          className=" bg-gray-200 absolute top-2 right-3 text-gray-700 text-sm border py-1 justify-between px-2 rounded-lg cursor-pointer space-x-1.5 flex"
          onClick={(e) => {
            setIsError(false);
            setDate(getDateStr());
          }}
        >
          <span>ตอนนี้</span>
          <BsFillCalendarEventFill className=" w-4 h-4 text-gray-500 transform duration-300 hover:scale-105" />
        </div>

        <input
          type="date"
          datepickerFormat="yyyy-mm-dd"
          pattern="\d{4}-\d{2}-\d{2}"
          max={max}
          min={min}
          language="th"
          className=" border border-gray-800 text-gray-800  text-sm rounded-lg  focus:ring-0 block w-full focus:outline-none disabled:text-black p-3 focus:border-gray-800 bg-gray-50 "
          onChange={(e) => {
            setIsError(false);
            setDate(e.target.value);
          }}
          id={id}
          value={date}
          required={required}
          disabled={disabled}
        />
      </div>

      <div className="ml-2">
        <span className=" text-xs text-red-500 font-medium ">
          {isError ? `ข้อมูล${label} ไม่ถูกต้อง!` : ""}
        </span>
      </div>
    </div>
  );
};

export default DateTextInput;
