import React from "react";

function CheckBoxInput(props) {
  const [value, setValue] = props.value;
  const { label, required, id, disabled } = props;

  return (
    <div className="flex items-center mb-4 pt-1.5">
      <input
        id={id}
        type="checkbox"
        checked={value}
        onChange={() => setValue(disabled ? value : !value)}
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-0 focus:outline-none focus:border-0 ring-0 outline-none border-0 "
        required={required}
      />

      <label className="ml-2 text-sm font-medium text-gray-100 dark:text-gray-300">
        {`${label ?? ""} ${required ? "*" : ""}`}
      </label>
    </div>
  );
}

export default CheckBoxInput;
