import React from "react";

const heads = [
  {
    title: "จำนวนบิล",
  },
  {
    title: "จุดส่งของ",
  },
  {
    title: "ระยะทาง",
  },
  {
    title: "",
  },
];

function TripsTable(props) {
  const [Trips, setTrips] = props.Trips;

  const handleDelete = (idx) => {
    const tripsCopy = [...Trips];
    tripsCopy.splice(idx, 1);
    setTrips(tripsCopy);
  };

  if (Trips.length > 0) {
    return (
      <div>
        <label className="block mb-2 text-sm text-gray-50 capitalize">
          รายการการส่งของ ({Trips.length})
        </label>

        <div className="relative overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 table ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                {heads?.map((head, idx) => (
                  <th
                    key={idx}
                    scope="col"
                    className=" bg-gray-200 px-3 py-1.5 whitespace-nowrap"
                  >
                    {head.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Trips?.map((trip, idx) => (
                <tr key={idx} className="bg-white border-b-2 ">
                  <th scope="row" className="px-6 py-1.5  ">
                    {trip?.AmountBill}
                  </th>
                  <td className="px-6 py-1.5">{trip?.AmountDrop}</td>
                  <td className="px-6 py-1.5 whitespace-nowrap">
                    {trip?.Distance}
                  </td>
                  <td className="px-6 py-1.5">
                    <button
                      className="font-medium text-red-600 hover:underline"
                      onClick={() => handleDelete(idx)}
                    >
                      ลบ
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TripsTable;
