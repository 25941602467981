import React from "react";
import Header from "./components/Header";

import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div className=" w-screen min-h-screen sm:h-screen h-full bg-gray-700 rounded-b-lg">
      <div className="w-full sm:w-[390px] h-full mx-auto sm:shadow-xl relative">
        {/* Header */}
        <div className=" z-50 top-2 right-0 fixed ">
          <Header title="KTT Registration" />
        </div>

        {/* Body */}
        <div className=" -translate-y-2 ">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
