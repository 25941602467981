import { useEffect, useState } from "react";
import { useMyState } from "../App";

// icons
import { FaUser, FaUserGroup } from "react-icons/fa6";
import { ImTruck } from "react-icons/im";

// utils
import getListObjByKey from "../utils/getListObjByKey";
import getRelativeTime from "../utils/getRelativeTime";

// components
import UserDetail from "./UserDetail";

function TabMain() {
  const { status, firstLoadData } = useMyState();
  const [showHelper, setShowHelper] = useState(false);

  useEffect(() => {
    if (firstLoadData) {
      if (firstLoadData?.User?.Position === "Helper") {
        setShowHelper(true);
      } else {
        if (firstLoadData?.Helper) {
          setShowHelper(true);
        }
      }
    }
  }, [firstLoadData]);

  return (
    <div className=" mx-2 space-y-2 mt-3">
      {(firstLoadData?.User || !status) && (
        <UserDetail
          Title="พนักงานขับรถ"
          Icon={FaUser}
          User={
            firstLoadData?.User?.Position === "Driver"
              ? firstLoadData?.User
              : firstLoadData?.Driver
          }
        />
      )}

      {(showHelper || !status) && (
        <UserDetail
          Title="ผู้ช่วยพนักงานขับรถ"
          Icon={FaUserGroup}
          User={
            firstLoadData?.User?.Position === "Helper"
              ? firstLoadData?.User
              : firstLoadData?.Helper
          }
        />
      )}

      <div className="flex space-x-2 items-center  ">
        <div className=" flex space-x-2 w-fit pt-1 px-1.5 rounded-md ">
          <ImTruck className={`w-4 h-4 text-white `} />
          <h1 className="text-sm font-semibold text-white">
            ข้อมูลสถานะล่าสุด
          </h1>
        </div>
      </div>

      <div className=" space-y-1.5 bg-gray-600 p-2 rounded-lg">
        {(firstLoadData?.User?.LastLogin || !status) && (
          <div className=" grid grid-cols-12 px-0">
            <span className="text-sm font-medium col-span-4 text-white">
              ใช้งานล่าสุด :
            </span>

            {!firstLoadData?.User ? (
              <div className=" max-w-sm animate-pulse col-span-8 mt-1">
                <div className="h-3 bg-gray-200 rounded-full max-w-[100px]"></div>
              </div>
            ) : (
              <span className=" text-sm text-gray-100 col-span-8">
                {getRelativeTime(firstLoadData?.User?.LastLogin)}
              </span>
            )}
          </div>
        )}

        {(firstLoadData?.User || !status) && (
          <div className=" grid grid-cols-12 px-0">
            <span className="text-sm font-medium col-span-4 text-white">
              ตอกบัตรล่าสุด :
            </span>
            {!firstLoadData?.User ? (
              <div className=" max-w-sm animate-pulse col-span-8 mt-1">
                <div className="h-3 bg-gray-200 rounded-full max-w-[100px]"></div>
              </div>
            ) : (
              <span className=" text-sm text-gray-100 col-span-8">
                {firstLoadData?.User?.LastWork === "PunchOut"
                  ? "ตอกบัตรออกงาน"
                  : firstLoadData?.User?.LastWork === "PunchIn"
                  ? "ตอกบัตรเข้างาน"
                  : "ไม่มี"}
              </span>
            )}
          </div>
        )}

        {(firstLoadData?.User?.LastTruck || !status) && (
          <>
            <div className=" grid grid-cols-12 px-0">
              <span className="text-sm font-medium col-span-4 text-white">
                รถที่ใช้ล่าสุด :
              </span>

              {!firstLoadData?.User ? (
                <div className=" max-w-sm animate-pulse col-span-8 mt-1">
                  <div className="h-3 bg-gray-200 rounded-full max-w-[100px]"></div>
                </div>
              ) : (
                <span className=" text-sm text-gray-100 col-span-8">
                  {firstLoadData?.User?.LastTruck}
                </span>
              )}
            </div>

            <div className=" grid grid-cols-12 px-0">
              <span className="text-sm font-medium col-span-4 text-white">
                ผู้ว่าจ้างล่าสุด :
              </span>
              {!firstLoadData?.User ? (
                <div className=" max-w-sm animate-pulse col-span-8 mt-1">
                  <div className="h-3 bg-gray-200 rounded-full max-w-[100px]"></div>
                </div>
              ) : (
                <span className=" text-sm text-gray-100 col-span-8">
                  {`${getListObjByKey(
                    firstLoadData?.Subcontracts,
                    firstLoadData?.User?.LastSubcontract,
                    "SubcontractID",
                    "SubcontractName"
                  )} (${firstLoadData?.User?.LastSubcontract})`}
                </span>
              )}
            </div>

            <div className=" grid grid-cols-12 px-0">
              <span className="text-sm font-medium col-span-4 text-white">
                สาขาล่าสุด :
              </span>
              {!firstLoadData?.User ? (
                <div className=" max-w-sm animate-pulse col-span-8 mt-1">
                  <div className="h-3 bg-gray-200 rounded-full max-w-[100px]"></div>
                </div>
              ) : (
                <span className=" text-sm text-gray-100 col-span-8">
                  {`${getListObjByKey(
                    firstLoadData?.Stores,
                    firstLoadData?.User?.LastStore,
                    "StoreCode",
                    "StoreName"
                  )} (${firstLoadData?.User?.LastStore})`}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TabMain;
