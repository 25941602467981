import React from "react";
import { BsCheckAll } from "react-icons/bs";
import { FaUser, FaStore, FaUserGroup } from "react-icons/fa6";
import { ImTruck } from "react-icons/im";

function Stepper(props) {
  const [curStep, setCurStep] = props.curStep;
  const { passStep } = props;

  return (
    <div className="  w-10/12 max-w-sm mx-auto ">
      <ol className="flex items-center w-full ">
        <li
          className=" flex w-full items-center text-blue-600 justify-start"
          onClick={() => (passStep >= 1 ? setCurStep(1) : null)}
        >
          <span
            className={`flex items-center justify-center w-10 h-10 ${
              curStep === 1
                ? "bg-gray-500 border-2 border-white"
                : curStep > 1
                ? "bg-blue-500 border-2 border-white"
                : "bg-white"
            } rounded-full lg:h-12 lg:w-12  shrink-0 `}
          >
            {curStep > 1 ? (
              <BsCheckAll
                className={`w-6 h-6 ${
                  curStep < 1 ? "text-gray-600" : "text-white"
                } lg:w-4 lg:h-4 `}
              />
            ) : (
              <FaUser
                className={`w-5 h-5 ${
                  curStep < 1 ? "text-gray-600" : "text-white"
                } lg:w-4 lg:h-4 `}
              />
            )}
          </span>

          <hr
            className={`w-full h-2 ${
              curStep === 1
                ? "bg-gray-400"
                : curStep > 1
                ? "bg-blue-500"
                : "bg-white"
            } transform duration-500 border-t-2 border-b-2 border-white`}
          />
        </li>

        <li
          className="flex w-full items-center justify-center "
          onClick={() => (passStep >= 2 ? setCurStep(2) : null)}
        >
          <hr
            className={`w-1/2 h-2 ${
              curStep > 1 ? "bg-blue-500" : "bg-white"
            } border-0 transform duration-500 border-t-2 border-b-2 border-white`}
          />

          <span
            className={`flex items-center justify-center w-10 h-10 ${
              curStep === 2
                ? "bg-gray-500 border-2 border-white"
                : curStep > 2
                ? "bg-blue-500 border-2 border-white"
                : "bg-white"
            } rounded-full lg:h-12 lg:w-12  shrink-0 `}
          >
            {curStep > 2 ? (
              <BsCheckAll
                className={`w-6 h-6  ${
                  curStep < 2 ? "text-gray-600" : "text-white"
                } lg:w-4 lg:h-4 `}
              />
            ) : (
              <FaUserGroup
                className={`w-5 h-5 ${
                  curStep < 2 ? "text-gray-600" : "text-white"
                } lg:w-4 lg:h-4 `}
              />
            )}
          </span>

          <hr
            className={`w-full h-2 ${
              curStep === 2
                ? "bg-gray-400"
                : curStep > 2
                ? "bg-blue-500"
                : "bg-white"
            } transform duration-500 border-t-2 border-b-2 border-white`}
          />
        </li>

        <li
          className="flex w-full items-center justify-center "
          onClick={() => (passStep >= 3 ? setCurStep(3) : null)}
        >
          <hr
            className={`w-full h-2 ${
              curStep > 2 ? "bg-blue-500" : "bg-white"
            } border-0 transform duration-500 border-t-2 border-b-2 border-white`}
          />

          <span
            className={`flex items-center justify-center w-10 h-10 ${
              curStep === 3
                ? "bg-gray-500 border-2 border-white"
                : curStep > 3
                ? "bg-blue-500 border-2 border-white"
                : "bg-white"
            } rounded-full lg:h-12 lg:w-12  shrink-0 `}
          >
            {curStep > 3 ? (
              <BsCheckAll
                className={`w-6 h-6  ${
                  curStep < 3 ? "text-gray-600" : "text-white"
                } lg:w-4 lg:h-4 `}
              />
            ) : (
              <ImTruck
                className={`w-5 h-5 ${
                  curStep < 3 ? "text-gray-600" : "text-white"
                } lg:w-4 lg:h-4 `}
              />
            )}
          </span>

          <hr
            className={`w-1/2 h-2 ${
              curStep === 3
                ? "bg-gray-400"
                : curStep > 3
                ? "bg-blue-500"
                : "bg-white"
            } transform duration-500 border-t-2 border-b-2 border-white`}
          />
        </li>

        <li
          className="flex w-full items-center justify-end"
          onClick={() => (passStep >= 4 ? setCurStep(4) : null)}
        >
          <hr
            className={`w-full h-2 ${
              curStep > 3 ? "bg-blue-500" : "bg-white"
            } border-0 transform duration-500 border-t-2 border-b-2 border-white`}
          />

          <span
            className={`flex items-center justify-center w-10 h-10 ${
              curStep === 4
                ? "bg-gray-500 border-2 border-white"
                : curStep > 4
                ? "bg-blue-500 border-2 border-white"
                : "bg-white"
            } rounded-full lg:h-12 lg:w-12  shrink-0 `}
          >
            {curStep > 4 ? (
              <BsCheckAll
                className={`w-6 h-6 ${
                  curStep < 4 ? "text-gray-600" : "text-white"
                } lg:w-4 lg:h-4 `}
              />
            ) : (
              <FaStore
                className={`w-5 h-5 ${
                  curStep < 4 ? "text-gray-600" : "text-white"
                } lg:w-4 lg:h-4 `}
              />
            )}
          </span>
        </li>
      </ol>
    </div>
  );
}

export default Stepper;
