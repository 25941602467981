export default function makeChoiceNum(
  end,
  size = 0.5,
  isList = false,
  start = 0
) {
  let res = [];
  for (let i = start; i <= end; i += size) {
    if (isList) {
      res.push(i);
    } else {
      res.push({
        label: i,
        value: i,
      });
    }
  }

  return res;
}
