import React from "react";

// components
import TextInput from "./TextInput";
import ImgUploadInput from "./ImgUploadInput";
import TimeTextInput from "./TimeTextInput";
import DateTextInput from "./DateTextInput";

// utils
import isDateToday from "../utils/isDateToday";
import getDateStr from "../utils/dateString";
import getPastDate from "../utils/getPastDate";
import getTimeStr from "../utils/getTimeStr";

function UserFormPunchIn(props) {
  const { User, PrefixId, SuffixId, disabledDate } = props;

  const [DateStr, setDateStr] = props.DateStr;
  const [DateStrError, setDateStrError] = props.DateStrError;

  const [TimeStr, setTimeStr] = props.TimeStr;
  const [TimeStrError, setTimeStrError] = props.TimeStrError;

  const ImgRef = props.ImgRef;
  const [SelectImg, setSelectImg] = props.SelectImg;
  const [ProgressUpload, setProgressUpload] = props.ProgressUpload;

  const [ImgUrl, setImgUrl] = props.ImgUrl;
  const [ImgUrlError, setImgUrlError] = props.ImgUrlError;

  return (
    <>
      <TextInput
        label="ชื่อ-สกุล"
        type="text"
        placeholder={User?.Name || ""}
        value={[User?.Name || "", () => {}]}
        error={[false, () => {}]}
        disabled
        required
      />

      <DateTextInput
        label="วันที่ตอกบัตร"
        id={`${PrefixId}-${SuffixId}`}
        min={getDateStr(getPastDate(7, 0, 0))}
        max={getDateStr()}
        value={[DateStr, setDateStr]}
        error={[DateStrError, setDateStrError]}
        disabled={disabledDate}
        required
      />

      <TimeTextInput
        label="เวลาตอกบัตร"
        max={isDateToday(DateStr) ? getTimeStr() : null}
        value={[TimeStr, setTimeStr]}
        error={[TimeStrError, setTimeStrError]}
        required
      />

      <ImgUploadInput
        id={`${PrefixId}-${SuffixId}`}
        label="รูปตอกบัตรเข้างาน"
        imgRef={ImgRef}
        url={[ImgUrl, setImgUrl]}
        error={[ImgUrlError, setImgUrlError]}
        selected={[SelectImg, setSelectImg]}
        progress={[ProgressUpload, setProgressUpload]}
        required
      />
    </>
  );
}

export default UserFormPunchIn;
