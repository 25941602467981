export default function isDateToday(dateString = "") {
  if (dateString === "") {
    return false;
  }

  // Split the date string into day, month, and year parts
  const parts = dateString.split("/");
  if (parts.length !== 3) {
    return false; // Invalid date string format
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);

  // Create a Date object for the input date string
  const inputDate = new Date(year, month, day);

  // Get the current date
  const currentDate = new Date();

  // Compare the input date with the current date
  return (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  );
}
