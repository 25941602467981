import { useState } from "react";
import { useMyState } from "../App";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { initializeApp } from "firebase/app";

// icons
import { BsImage, BsTrashFill } from "react-icons/bs";
import { ImSpinner9 } from "react-icons/im";
import { IoClose } from "react-icons/io5";

// apis
import { uploadImage } from "../apis/proxy";

// components
import ImgFullView from "./ImgFullView";

// utils
import cap from "../utils/cap";
import firebaseConfig from "../data/firebaseConfig";

initializeApp(firebaseConfig);
const storage = getStorage();

function ImgUploadInput(props) {
  const { setStatus, userData } = useMyState();
  const [url, setUrl] = props.url;
  const [isError, setIsError] = props.error;
  const [selectedImage, setSelectedImage] = props.selected;
  const [progress, setProgress] = props.progress;
  const { label, required, disabled, hidden, id, imgRef } = props;

  const [isUploading, setIsUploading] = useState(null);
  const [isFullImage, setIsFullImage] = useState(false);

  const deleteImage = (imageUrl) => {
    const storage = getStorage();
    const imageRef = ref(storage, imageUrl); // Reference to the image in Firebase Storage

    // Delete the image
    deleteObject(imageRef)
      .then(() => {
        setUrl("");
        setIsUploading(null);
        setIsFullImage(false);
        setIsError(false);
        setProgress(0);
        setSelectedImage(null);
        console.log("Image deleted successfully.");
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
  };

  const handleUpload = () => {
    setIsError(false);

    const metadata = {
      contentType: "image/jpeg",
    };

    try {
      const storageRef = ref(storage, "images/" + selectedImage.name);
      const uploadTask = uploadBytesResumable(
        storageRef,
        selectedImage,
        metadata
      );

      uploadTask.on(
        "state_changed",
        (snap) => {
          const progress = (snap.bytesTransferred / snap.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.log(error);
          alert(`Image upload error ${JSON.stringify(error)}`);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUrl(downloadURL);
          });
        }
      );
    } catch (error) {
      alert(`Image upload error ${JSON.stringify(error)}`);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleClickUpload = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("image", selectedImage);

      try {
        setIsUploading(true);
        setStatus(false);
        const res = await uploadImage(formData, userData.UserID, id);
        setIsUploading(false);
        setStatus(true);

        if (res.status === 200) {
          if (res.data?.Status === 200) {
            setUrl(res.data?.Data?.Url);
          } else {
            alert(res.data?.Message);
          }
        } else {
          alert("network error.");
        }
      } catch (error) {
        alert(error?.response ?? "error");
      }
    } else {
      alert("No image selected.");
    }
  };

  if (!hidden) {
    return (
      <div id={id}>
        <ImgFullView isFullImage={[isFullImage, setIsFullImage]} imgUrl={url} />

        <div className="col-span-6 sm:col-span-3 ">
          <label className="block mb-2 text-sm text-gray-50 capitalize">
            {`อัพโหลด${label ?? ""} ${required ? "*" : ""}`}
          </label>

          <input
            ref={imgRef}
            className="hidden"
            id={id}
            type="file"
            accept="image/*"
            disabled={disabled}
            onClick={() => setIsError(false)}
            onChange={(e) => {
              setIsError(false);
              setSelectedImage(e.target.files[0]);
            }}
          />

          <div className="relative rounded-lg flex justify-between space-x-1">
            <div
              className={`block w-full text-sm border border-gray-800 text-gray-800 rounded-lg cursor-pointer focus:outline-none  p-3 relative ${
                selectedImage ? "bg-gray-500" : "bg-gray-50"
              } `}
              onClick={(e) => {
                if (url !== "") {
                  setIsFullImage(true);
                } else {
                  if (!selectedImage) {
                    imgRef.current.removeAttribute("capture");
                    imgRef.current.click();
                  }
                }
              }}
            >
              <div
                className="bg-gray-600 h-1.5 z-20 top-0 left-0 rounded-t-lg absolute transform duration-300"
                style={{ width: `${progress}%` }}
              ></div>

              <span
                className={`${selectedImage ? "text-white" : "text-black"}`}
              >
                {selectedImage
                  ? cap(
                      selectedImage?.name,
                      url !== "" || !selectedImage ? 25 : 18
                    )
                  : `เลือกรูปภาพ`}
              </span>

              {!selectedImage && url === "" && (
                <div
                  className=" bg-gray-200 absolute top-2 right-3 text-gray-700 text-sm border py-1 px-2 rounded-lg cursor-pointer space-x-1.5 flex"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsError(false);
                    if (url !== "") {
                      setIsFullImage(true);
                    } else {
                      if (!selectedImage) {
                        imgRef.current.capture = "camera";
                        imgRef.current.click();
                      }
                    }
                  }}
                >
                  <span>ถ่ายรูป</span>
                  <BsImage
                    className={` w-5 h-5 ${
                      url !== ""
                        ? "opacity-100 text-gray-300"
                        : selectedImage
                        ? "opacity-50 text-gray-600"
                        : " text-gray-600 "
                    } w-5 h-5 text-gray-500 transform duration-300 hover:scale-105  `}
                  />
                </div>
              )}

              <IoClose
                hidden={!selectedImage || url !== "" || isUploading}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedImage(null);
                }}
                className={` absolute top-2 right-2 w-7 h-7 text-red-500 transform duration-300 hover:scale-105 `}
              />

              <BsTrashFill
                hidden={url === ""}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteImage(url);
                }}
                className={` absolute top-3 right-2 w-6 h-6 text-red-500 transform duration-300 hover:scale-105 `}
              />
            </div>

            {url === "" && selectedImage && (
              <button
                className={` w-4/12 text-sm  border border-gray-800 text-gray-800 rounded-lg cursor-pointer focus:outline-none  p-2 justify-center flex items-center  ${
                  isUploading
                    ? "bg-gray-50 hover:bg-gray-300"
                    : "bg-gray-300 hover:bg-gray-300"
                } `}
                disabled={isUploading || !selectedImage}
                onClick={() =>
                  setIsUploading((prev) => {
                    if (!prev) {
                      handleUpload();
                    }
                    return prev;
                  })
                }
              >
                {isUploading ? (
                  <div className="flex space-x-1 text-gray-600 ">
                    <ImSpinner9 className=" animate-spin w-3.5 h-3.5 mt-0.5 " />
                    <span className=" text-sm">อัพโหลด...</span>
                  </div>
                ) : (
                  "อัพโหลด"
                )}
              </button>
            )}
          </div>

          <div className="ml-2">
            <span className=" text-xs text-red-500 font-medium ">
              {isError ? `กรุณากดอัพโหลดรูปภาพ` : ""}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ImgUploadInput;
