import { IoMdClose } from "react-icons/io";
import { BsFillCheckCircleFill } from "react-icons/bs";

function AlertModal(props) {
  const [isOpen, setIsOpen] = props.isYesNoModalOpen;
  const { message } = props;

  return (
    <div
      className={
        (!isOpen ? "hidden" : "") +
        " grid place-items-center justify-center z-50 items-center justify-items-center"
      }
    >
      <div className="fixed inset-0 h-screen z-50 bg-black bg-opacity-50 "></div>

      <div
        id="popup-modal"
        className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative w-full max-w-xs max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => setIsOpen(false)}
            >
              <IoMdClose className="w-6 h-6" />
            </button>

            <div className="p-6 text-center ">
              <BsFillCheckCircleFill className="mx-auto mb-4 text-green-500 w-12 h-12 " />
              <h3 className=" text-lg font-normal text-gray-500 dark:text-gray-400 ">
                {message}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertModal;
