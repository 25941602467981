import { useState, useEffect } from "react";
import { IoReloadCircleSharp } from "react-icons/io5";
import { FaUser, FaUserGroup } from "react-icons/fa6";
import { LiaAngleDoubleLeftSolid } from "react-icons/lia";
import { useMyState } from "../App";

import positionList from "../data/positionList";
import getListObjByKey from "../utils/getListObjByKey";
import cap from "../utils/cap";

function Header() {
  const {
    status,
    userData,
    showUser,
    setShowUser,
    firstLoadData,
    logout,
    sendText,
    version,
  } = useMyState();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (!showUser) {
      setShowMenu(false);
    }
  }, [showUser]);

  const menuList = [
    { label: "รีโหลด", onClick: () => window.location.reload() },
    { label: "ออกจากระบบ", onClick: logout },
  ];

  return (
    <div
      className={` flex w-full justify-end transform ${
        showUser ? "" : "translate-x-[156px]"
      } transition-transform duration-700 relative`}
    >
      {showMenu && (
        <div
          onClick={() => setShowMenu(false)}
          className=" mx-[25px] absolute w-full h-full top-0 left-0 z-[60] flex  items-center justify-center backdrop-blur-sm  "
        >
          <div className=" absolute z-[70] bg-gray-50  rounded-lg mt-0.5 shadow-md max-h-52  min-w-max  ">
            <ul className=" text-sm text-gray-600 divide-y divide-gray-100 rounded-lg   ">
              {menuList?.map((item, idx) => (
                <li key={idx} className="w-full">
                  <button
                    type="button"
                    className={`inline-flex w-full text-left px-3 py-1.5 hover:bg-gray-200 ${
                      idx === 0
                        ? "rounded-t-lg"
                        : idx === menuList.length - 1
                        ? "rounded-b-lg"
                        : ""
                    }  `}
                    onClick={item?.onClick}
                  >
                    {item?.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <div
        className="rounded-l-md bg-gray-200 w-[25px] flex justify-center items-center"
        onClick={() => setShowUser(!showUser)}
      >
        <div className=" space-y-2 ">
          <LiaAngleDoubleLeftSolid
            className={`w-5 h-5 text-gray-600 ${showUser ? "rotate-180" : ""}`}
          />
          <LiaAngleDoubleLeftSolid
            className={`w-5 h-5 text-gray-600 ${showUser ? "rotate-180" : ""}`}
          />
        </div>
      </div>

      <div className="bg-green-500 h-[10vh] w-[156px] items-center flex px-2 space-x-3 justify-between z-50">
        <div className=" ">
          <h2 className="text-[6px] text-gray-100 mt-0.5 absolute top-0 right-2">
            version: {version}
          </h2>
          <div
            hidden={userData?.DisplayName}
            className="h-4 w-16 bg-gray-200 animate-pulse rounded-md my-1"
          ></div>
          <h2 className=" text-sm text-white" hidden={!userData?.DisplayName}>
            {cap(userData?.DisplayName ?? "")}
          </h2>

          <div className=" flex space-x-1 items-center ">
            {firstLoadData?.User?.Position === "Driver" ? (
              <FaUser className="w-2.5 h-2.5 text-gray-100 " />
            ) : firstLoadData?.User?.Position === "Helper" ? (
              <FaUserGroup className="w-2.5 h-2.5 text-gray-100 " />
            ) : status ? (
              <IoReloadCircleSharp className="w-3 h-3 text-gray-100" />
            ) : (
              <IoReloadCircleSharp className="w-3 h-3 text-gray-100 animate-spin" />
            )}

            <h2 className=" text-[10px] text-gray-100 mt-0.5 ">
              {!status
                ? "กำลังโหลด..."
                : firstLoadData?.User
                ? cap(
                    getListObjByKey(
                      positionList,
                      firstLoadData?.User?.Position,
                      "value",
                      "label"
                    ),
                    14
                  )
                : "ไม่พบข้อมูล"}
            </h2>
          </div>
        </div>

        <div
          className="w-10 h-10 rounded-full border relative"
          hidden={!userData?.DisplayName}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="w-full h-full border bg-gray-200 rounded-full absolute left-0 top-0 z-0"></div>
          <img
            src={userData?.Picture ?? ""}
            className="w-full h-full border rounded-full z-20 absolute left-0 top-0"
            alt={userData?.DisplayName ?? ""}
          />
        </div>

        <div
          className="w-10 h-10 rounded-full border-2"
          hidden={userData?.DisplayName}
        >
          <div className="w-full h-full bg-gray-200 rounded-full animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}

export default Header;
