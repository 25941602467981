import { useState, useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import getListObjByKey from "../utils/getListObjByKey";

function SelectInput(props) {
  const [value, setValue] = props.value;
  const [isError, setIsError] = props.error;

  const { label, disabled, required, list, hidden, defaultValue } = props;
  const [showItem, setShowItem] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue]);

  return (
    <div className="col-span-6 sm:col-span-3" hidden={hidden}>
      <div
        hidden={!showItem}
        onClick={() => setShowItem(false)}
        className=" top-0 right-0 absolute h-screen w-full z-20  "
      ></div>

      <label className="block mb-2 text-sm text-gray-50 capitaliz">
        {`${label ?? ""} ${required ? "*" : ""}`}
      </label>

      <div className="rounded-lg relative">
        <BiChevronDown
          className={`${
            showItem ? "rotate-180" : ""
          } absolute top-2 right-2 w-6 h-6 text-gray-500 `}
        />

        <div
          type="text"
          className={` border border-gray-800   text-sm rounded-lg  focus:ring-0  w-full focus:outline-none disabled:text-black p-3 focus:border-gray-800 overflow-visible ${
            disabled ? "bg-gray-500 text-gray-200" : "bg-gray-50 text-gray-800"
          } `}
          onClick={() => setShowItem(disabled ? false : !showItem)}
        >
          {getListObjByKey(list, value, "value", "label") || props.placeholder}
        </div>

        <div
          hidden={!showItem}
          className=" absolute w-full bg-white z-30 rounded-lg mt-0.5 shadow-md max-h-52 overflow-y-auto min-w-max  "
          onClick={() => setShowItem(false)}
        >
          <ul className=" text-sm text-gray-600 divide-y divide-gray-100 rounded-lg  ">
            {list?.map((item, idx) => (
              <li key={idx} className="w-full">
                <button
                  type="button"
                  className="inline-flex w-full text-left px-3 py-2.5 rounded-lg  hover:bg-gray-100   "
                  onClick={() => {
                    setIsError(false);
                    setValue(item?.value);
                  }}
                >
                  {item?.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="ml-2">
        <span className=" text-xs text-red-500 font-medium ">
          {isError ? `ข้อมูล${label} ไม่ถูกต้อง!` : ""}
        </span>
      </div>
    </div>
  );
}

export default SelectInput;
