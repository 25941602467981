const dateStr2Date = (dateString) => {
  const datePattern = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
  const match = dateString.match(datePattern);

  if (!match) {
    return false;
  }

  const day = parseInt(match[1], 10);
  const month = parseInt(match[2], 10) - 1; // Months are zero-based
  const year = parseInt(match[3], 10);

  return new Date(year, month, day);
};

export { dateStr2Date };

export default function isValidDate(startDateString, endDateString) {
  const startDate = dateStr2Date(startDateString);
  if (!startDate) {
    return false;
  }

  let endDate;
  if (endDateString) {
    endDate = dateStr2Date(endDateString);
    if (!endDate) {
      return false;
    }
  } else {
    endDate = new Date();
  }

  return startDate <= endDate;
}
