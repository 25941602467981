import { AiOutlineClockCircle } from "react-icons/ai";
import getTimeStr from "../utils/getTimeStr";

function TimeTextInput(props) {
  const [value, setValue] = props.value;
  const [isError, setIsError] = props.error;
  const { label, disabled, required, hidden, max, min } = props;

  return (
    <div className="col-span-6 sm:col-span-3" hidden={hidden}>
      <label className="block mb-2 text-sm text-gray-50 capitaliz">
        {`${label ?? ""} ${required ? "*" : ""}`}
      </label>

      <div className="rounded-lg relative">
        <div
          className=" bg-gray-200 absolute top-2 right-3 text-gray-700 text-sm border py-1 px-2 rounded-lg cursor-pointer space-x-1.5 flex"
          onClick={(e) => {
            e.stopPropagation();
            setIsError(false);
            setValue(getTimeStr());
          }}
        >
          <span>ตอนนี้</span>
          <AiOutlineClockCircle className=" w-5 h-5 text-gray-500 transform duration-300 hover:rotate-90 hover:scale-105 " />
        </div>

        <input
          type="time"
          pattern="[0-9]{2}:[0-9]{2}"
          max={max}
          min={min}
          className=" border border-gray-800 text-gray-800  text-sm rounded-lg  focus:ring-0 block w-full focus:outline-none disabled:text-black p-3 focus:border-gray-800 bg-gray-50 "
          value={value}
          onChange={(e) => {
            setIsError(false);
            setValue(e.target.value);
          }}
          required={required}
          disabled={disabled}
        />
      </div>

      <div className="ml-2">
        <span className=" text-xs text-red-500 font-medium ">
          {isError ? `ข้อมูล${label} ไม่ถูกต้อง!` : ""}
        </span>
      </div>
    </div>
  );
}

export default TimeTextInput;
