import React from "react";
import { ImSpinner9 } from "react-icons/im";

function ImgFullView(props) {
  const { imgUrl } = props;
  const [isFullImage, setIsFullImage] = props?.isFullImage;

  if (isFullImage) {
    return (
      <div
        className="absolute inset-0 flex items-center justify-center backdrop-blur-md z-40 w-full h-screen"
        onClick={() => setIsFullImage(false)}
      >
        <div className="max-w-xs mx-auto bg-gray-200 rounded-lg p-2  relative ">
          <div className=" left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 absolute -z-10">
            <ImSpinner9 className=" text-gray-700 animate-spin w-10 h-10 " />
          </div>

          <img
            className="max-w-full h-auto rounded-lg z-10"
            src={imgUrl}
            alt=""
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
    );
  }
}

export default ImgFullView;
