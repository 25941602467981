/* eslint-disable react-hooks/exhaustive-deps */

import { Routes, Route, useNavigate } from "react-router-dom";
import { useState, useEffect, createContext, useContext } from "react";
import liff from "@line/liff";
import "./App.css";

// components
import Layout from "./Layout";
import Register from "./pages/Register";
import Main from "./pages/Main";
import PunchIn from "./pages/PunchIn";
import PunchOut from "./pages/PunchOut";

// utils
import { firstLoad } from "./apis/proxy";

// demo data
import liffData from "./data/liffData";
import resfirstLoadData from "./data/resfirstLoadData";

const MyStateContext = createContext();
export { MyStateContext };

function App() {
  const isDemo = false;
  const isUseApi = true;

  const version = "1.2.26";
  const liffId = "2000769684-l59ZrWX9";
  const navigate = useNavigate();

  const [status, setStatus] = useState(false);
  const [userData, setUserData] = useState(null);
  const [firstLoadData, setFirstLoadData] = useState(null);
  const [showUser, setShowUser] = useState(true);

  const sendText = async (...texts) => {
    let messages = [];
    if (texts.length <= 5) {
      for (const text of texts) {
        messages.push({
          type: "text",
          text: text,
        });
      }
    }

    await liff.sendMessages(messages);
  };

  const sendMessages = async (messages) => {
    try {
      await liff.sendMessages(messages);
    } catch (error) {
      alert(`ไม่สามารถส่งแจ้งเตือนผ่านไลน์ได้`);
    }
  };

  const sendFlex = async (flex, alt = "Flex") => {
    const message = {
      type: "flex",
      altText: alt,
      contents: flex,
    };

    if (liff.isInClient()) {
      try {
        await liff.sendMessages([message]);
        return 1;
      } catch (error) {
        alert(JSON.stringify(error));
        return -1;
      }
    }
  };

  const close = () => {
    if (liff.isInClient()) {
      liff.closeWindow();
    } else {
      window.close();
      window.location.href = "/";
    }
  };

  const logout = () => {
    liff.logout();
    window.location.reload();
  };

  const initLine = async () => {
    await liff.init(
      { liffId: liffId },
      async () => {
        if (liff.isLoggedIn()) {
          await runApp();
        } else {
          liff.login();
        }
      },
      (err) => console.error(err)
    );
  };

  const runApp = async () => {
    try {
      const profile = await liff.getProfile();
      saveProfile(profile);
      return profile.userId;
    } catch (err) {
      console.error(err);
    }
  };

  const saveProfile = (profile) => {
    setUserData({
      Picture: profile.pictureUrl,
      DisplayName: profile.displayName,
      UserID: profile.userId,
      StatusMessage: profile.statusMessage,
    });
  };

  const getFirstLoad = async (userid) => {
    try {
      console.log("firstLoad fetching...");
      const res = await firstLoad(userid);

      if (res.status === 200) {
        if (res.data?.Status === 200) {
          setFirstLoadData(res?.data?.Data);
          // console.log(res?.data?.Data);
        } else if (res.data?.Status === 404) {
          navigate("/register");
        } else {
          alert(res.data?.Message);
        }
      } else {
        alert("network error.");
      }
    } catch (error) {
      alert(error?.response ?? "error");
    }
  };

  const onLoading = async (userid) => {
    setStatus(false);
    await getFirstLoad(userid || userData?.UserID);
    setStatus(true);
    setTimeout(() => {
      setShowUser(false);
    }, 1000);
  };

  useEffect(() => {
    if (!isDemo) {
      console.log("InitLine working...");
      initLine();
    }
  }, []);

  useEffect(() => {
    if (isDemo) {
      // alert("Demo mode is on");
      setUserData(liffData);

      if (isUseApi) {
        onLoading(liffData.UserID);
      } else {
        setFirstLoadData(resfirstLoadData.Data);
        setStatus(true);
        setTimeout(() => {
          setShowUser(false);
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (!isDemo) {
      if (userData) {
        if (window.location.pathname !== "/register") {
          console.log("Onload working...");
          onLoading();
        } else {
          console.log("timeout set status...");
          setStatus(true);
          setTimeout(() => {
            setShowUser(false);
          }, 1000);
        }
      }
    }
  }, [userData]);

  const states = {
    sendMessages,
    version,
    sendFlex,
    sendText,
    close,
    logout,
    status,
    setStatus,
    showUser,
    setShowUser,
    userData,
    setUserData,
    firstLoadData,
    setFirstLoadData,
  };

  return (
    <MyStateContext.Provider value={states}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="register" element={<Register />} />
          <Route path="punchin" element={<PunchIn />} />
          <Route path="punchout" element={<PunchOut />} />
          <Route path="*" element={<div>no page</div>} />
        </Route>
      </Routes>
    </MyStateContext.Provider>
  );
}

export function useMyState() {
  const context = useContext(MyStateContext);
  if (!context) {
    throw new Error("useStatus must be used within a StatusProvider");
  }
  return context;
}

export default App;
