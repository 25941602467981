import React from "react";

function UserDetail(props) {
  const { User, Title, Icon } = props;

  return (
    <>
      <div className="flex space-x-2 items-center ">
        <div className=" flex space-x-2 w-fit pt-1 px-1.5 rounded-md ">
          <Icon className={`w-4 h-4 text-white `} />
          <h1 className="text-sm font-semibold text-white">{Title}</h1>
        </div>
      </div>

      <div className=" space-y-1.5 bg-gray-600 p-2 rounded-lg">
        <div className=" grid grid-cols-12 px-0">
          <span className="text-sm font-medium col-span-4 text-white">
            ชื่อ-นามสกุล :
          </span>

          {!User ? (
            <div className=" max-w-sm animate-pulse col-span-8 mt-1">
              <div className="h-3 bg-gray-200 rounded-full max-w-[100px]"></div>
            </div>
          ) : (
            <span className=" text-sm text-gray-100 col-span-8">
              {User?.Name}
            </span>
          )}
        </div>

        <div className=" grid grid-cols-12 px-0">
          <span className="text-sm font-medium col-span-4 text-white">
            เบอร์โทร :
          </span>
          {!User ? (
            <div className=" max-w-sm animate-pulse col-span-8 mt-1">
              <div className="h-3 bg-gray-200 rounded-full max-w-[100px]"></div>
            </div>
          ) : (
            <span className=" text-sm text-gray-100 col-span-8">
              0{User?.Tel}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default UserDetail;
